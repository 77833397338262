import React, { Component } from "react";
import './Footer.scss'
import { Container, Row, Col } from "react-bootstrap";
import AppVersion from "../../utill/app-version";

class Footer extends Component {
  
  state = {};
  render() {
    return (
      <div className="footer-main">
        <Container fluid>
          <Row>
            <Col className="text-center">&copy; All Rights Reserved  <span className="text-muted" title={AppVersion.build}>| {AppVersion.version}</span></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;

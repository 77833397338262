import React from 'react';

const SecondsConverter = (props) => {
  const { seconds } = props;

  const h = Math.floor((seconds / (60 * 60)) % 24);
  const m = Math.floor((seconds / 60) % 60);
  const s = Math.floor(seconds % 60);

  const hDisplay = (h < 10) ? '0' + h : h;
  const mDisplay = (m < 10) ? '0' + m : m;
  const sDisplay = (s < 10) ? '0' + s : s;
  const readFormat = hDisplay + 'h ' + mDisplay + 'm ' + sDisplay + 's';

  return (`${readFormat}`);
}

export default SecondsConverter;
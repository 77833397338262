import React, { Component } from 'react';
import { Form, Col, InputGroup } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import {
  ecuDetailsApi,
  supplierDetailsApi,
  getEcuNameApi
} from '../../../../utill/api/ecuApi';
import { getParametersApi } from "../../../../utill/api/schedulesApi";
import InlineAlert from '../../../../shared/notificationAlert/inlineAlert';
import Select from 'react-select';

const animatedComponents = makeAnimated();

class UpdateParamBulk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      disable: false,
      error: null,
      type: this.props.scheduleType,
      parameters: [],
      selectedParameter: {},
      parameterValue: null,
      isParameterSelected: null,
      ecuDetails: [],
      selectedEcuId: '',
      selectedEcuOtaFlsRefName: '',
      selectedEcu: '',
      ecuGroup: '',
      ecuName: '',
      supplierDetails: [],
      selectedSupplier: '',
      selectedSupplierId: '',
      selectedIAlertVpartId: '',
      description: null,
      scheduleExpiryDays: 14,
      customFileName: null,
    }
    this.getEcuDetails = this.getEcuDetails.bind(this);
    this.getSupplierDetails = this.getSupplierDetails.bind(this);
  }

  componentDidMount() {
    this.getEcuDetails();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isDataStored } = nextProps;
    if (isDataStored) this.setState({ customFileName: null });
  }

  getEcuDetails() {
    ecuDetailsApi(null)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(ecu => { ecu.label = `${ecu.ecuGroup}`; });
              // Update parent with the selected changes
              this.props.handleChange('ecuGroup', res[0].ecuGroup);
              this.props.handleChange('ecuGroupId', res[0].id);
              // set current state values
              this.setState({
                ecuDetails: res,
                selectedEcu: res[0].label,
                selectedEcuId: res[0].id,
                ecuGroup: res[0].ecuGroup,
                // ecuName: res[0].model
              }, () => this.getSupplierDetails());
            } else {
              // empty parent values
              this.props.handleChange('ecuGroup', null);
              this.props.handleChange('ecuName', null);
              this.props.handleChange('ecuGroupId', null);
              this.props.handleChange('supplier', null);
              this.props.handleChange('supplierId', null);
              this.props.handleChange('selectedIAlertVpartId', null);
              this.props.handleChange('updateParameter', {});
              this.props.handleChange('isParameterSelected', null);
              this.props.handleChange('selectedEcuOtaFlsRefName', null);

              
              // empty current state values
              this.setState({
                ecuDetails: [],
                selectedEcuId: '',
                selectedEcu: '',
                ecuGroup: '',
                ecuName: '',
                supplierDetails: [],
                selectedSupplier: '',
                selectedSupplierId: '',
                selectedIAlertVpartId: '',
                selectedEcuOtaFlsRefName: '',
                parameters: [],
                selectedParameter: {},
                isParameterSelected: null,
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getSupplierDetails() {
    supplierDetailsApi(this.state.selectedEcu, null)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(sup => { sup.label = `${sup.supplier}` });
              // Update parent with the selected changes
              this.props.handleChange('supplier', res[0].supplier);
              this.props.handleChange('supplierId', res[0].id);
              this.props.handleChange('selectedIAlertVpartId', res[0].iAlertVPartNumberId);
              this.props.handleChange('selectedEcuOtaFlsRefName', res[0].ecuOtaFlsRefName);
              // set current state values
              this.setState({
                supplierDetails: res,
                selectedSupplier: res[0].label,
                selectedSupplierId: res[0].id,
                selectedIAlertVpartId: res[0].iAlertVPartNumberId,
                selectedEcuOtaFlsRefName: res[0].ecuOtaFlsRefName

                // ecuName: res[0].connectAllEcuName
              }, () => this.getParameters());
            } else {
              // empty parent values
              this.props.handleChange('supplier', null);
              this.props.handleChange('supplierId', null);
              this.props.handleChange('selectedIAlertVpartId', null);
              this.props.handleChange('updateParameter', {});
              this.props.handleChange('isParameterSelected', null);
              this.props.handleChange('selectedEcuOtaFlsRefName', null);
              // empty current state values
              this.setState({
                supplierDetails: [],
                selectedSupplier: '',
                selectedSupplierId: '',
                selectedIAlertVpartId: '',
                selectedEcuOtaFlsRefName: '',
                ecuName: '',
                parameters: [],
                selectedParameter: {},
                isParameterSelected: null,
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getEcuName = () => {
    const { selectedEcuId, selectedSupplierId } = this.state;
    getEcuNameApi(selectedEcuId, selectedSupplierId, null)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            // Update parent with the selected changes
            this.props.handleChange('ecuName', res.ecuName);
            // set current state values
            this.setState({
              ecuName: res.ecuName
            }, () => this.getParameters());
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getParameters = () => {
    getParametersApi(this.state.type, this.state.selectedEcuOtaFlsRefName)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(response => {
            if (response.length) {
              const temp = [];
              response.map(item => {
                const obj = {};
                obj.value = item.id;
                obj.label = item.description;
                obj.lid = item.lidHex;
                obj.referenceId = item.referenceId;
                temp.push(obj);
              });
              this.setState({ parameters: temp });
            } else this.setState({ parameters: [] });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  selectParameters = (values) => {
    if (values !== null && values.referenceId) {
      // Update parent with the selected changes
      this.props.handleChange('updateParameter', values);
      this.props.handleChange('isParameterSelected', true);
      // set current state values
      this.setState({ isParameterSelected: true, selectedParameter: values });
    } else {
      // empty parent values
      this.props.handleChange('updateParameter', {});
      this.props.handleChange('isParameterSelected', false);
      // empty current state values
      this.setState({ isParameterSelected: false, selectedParameter: {} });
    }
  }

  onEcuGroupChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { ecuDetails } = this.state;
    // Update parent with the selected changes
    this.props.handleChange('ecuGroup', ecuDetails[selectedIndex].ecuGroup);
    this.props.handleChange('ecuGroupId', ecuDetails[selectedIndex].id);
    // this.props.handleChange('ecuName', ecuDetails[selectedIndex].model);
    // empty parent values
    this.props.handleChange('updateParameter', {});
    this.props.handleChange('isParameterSelected', null);
    // set current state values
    this.setState({
      selectedEcu: ecuDetails[selectedIndex].label,
      selectedEcuId: ecuDetails[selectedIndex].id,
      ecuGroup: ecuDetails[selectedIndex].ecuGroup,
      isParameterSelected: null,
      selectedParameter: {}
      // ecuName: ecuDetails[selectedIndex].model
    }, () => this.getSupplierDetails());
  }

  onSupplierChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { supplierDetails } = this.state;
    // Update parent with the selected changes
    this.props.handleChange('supplier', supplierDetails[selectedIndex].supplier);
    this.props.handleChange('supplierId', supplierDetails[selectedIndex].id);
    this.props.handleChange('selectedIAlertVpartId', supplierDetails[selectedIndex].iAlertVPartNumberId);
    this.props.handleChange('selectedEcuOtaFlsRefName', supplierDetails[selectedIndex].ecuOtaFlsRefName);
    

    // empty parent values
    this.props.handleChange('updateParameter', {});
    this.props.handleChange('isParameterSelected', null);
    // set current state values
    this.setState({
      selectedSupplier: supplierDetails[selectedIndex].label,
      selectedSupplierId: supplierDetails[selectedIndex].id,
      selectedIAlertVpartId: supplierDetails[selectedIndex].iAlertVPartNumberId,
      selectedEcuOtaFlsRefName: supplierDetails[selectedIndex].ecuOtaFlsRefName,
      isParameterSelected: null,
      selectedParameter: {}
      // ecuName: supplierDetails[selectedIndex].connectAllEcuNamed
    }, () => this.getParameters());
  }

  onParameterValueChange = (event) => {
    // Update parent with the selected changes
    this.props.handleChange('parameterValue', event.target.value);
    this.setState({ parameterValue: event.target.value });
  }

  onCustomFileSelect = (e) => {
    let name = '';
    if (!e.target.files[0]) name = null;
    else name = e.target.files[0].name;
    this.setState({ customFileName: name });
  };

  onDescriptionKeyPress = (e) => {
    this.setState({ description: e.target.value });
  }

  onExpiryKeyPress = (e) => {
    this.setState({ scheduleExpiryDays: e.target.value });
  }

  render() {
    const { currentStep, isDataStored } = this.props;
    if (currentStep !== 1 || isDataStored) { // Prop: The current step
      return null
    }
    const {
      ecuDetails,
      selectedEcu,
      selectedSupplier,
      supplierDetails,
      parameters,
      selectedParameter,
      parameterValue,
      description,
      scheduleExpiryDays,
      customFileName
    } = this.state;
    const { isParameterSelected } = this.props;
    return (
      <>
        {/* Error Display */}
        {Boolean(this.state.error) ? (
          <InlineAlert error={this.state.error} />
        ) : null}
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="ecuGroup">
            <Form.Label>ECU Group</Form.Label>
            <Form.Control
              as="select"
              required
              name="ecuGroup"
              value={selectedEcu}
              onChange={this.onEcuGroupChange}
            >
              {ecuDetails.map((item, i) => {
                return (
                  <option key={i} value={item.label}>{item.label}</option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a Group.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="supplier">
            <Form.Label>Supplier</Form.Label>
            <Form.Control
              as="select"
              required
              name="supplier"
              value={selectedSupplier}
              onChange={this.onSupplierChange}
            >
              {supplierDetails.map((item, i) => {
                return (
                  <option key={i} value={item.label}>{item.label}</option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a Supplier.
            </Form.Control.Feedback>
          </Form.Group>

          {/* Parameter Selection */}
          <Form.Group as={Col} md="6" controlId="selectVehicles">
            <Form.Label>Select Parameter</Form.Label>
            <Select
              options={parameters}
              value={selectedParameter}
              components={animatedComponents}
              onChange={this.selectParameters}
            />
            {isParameterSelected !== null && !isParameterSelected ? (
              <>
                <small className="text-danger">
                  Please select a Parameter.
              </small>
              </>
            ) : null}
          </Form.Group>

          {/* Parameter Value */}
          <Form.Group as={Col} md="6" controlId="validationCustompv">
            <Form.Label>Parameter Value</Form.Label>
            <Form.Control
              required
              name="parameterValue"
              type="text"
              placeholder="Parameter Value"
              maxLength={45}
              defaultValue={parameterValue}
              onKeyUp={(e) => this.onParameterValueChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter Parameter Value.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="12" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              required
              name="description"
              type="text"
              placeholder="Description"
              maxLength={45}
              defaultValue={description}
              onKeyUp={(e) => this.onDescriptionKeyPress(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter schedule description.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="12" controlId="file">
            <Form.Label>Select File</Form.Label>
            <Form.File id="custom-file" custom>
              <Form.File.Input
                name="file" 
                required custom
                onChange={(e) => this.onCustomFileSelect(e)}
              />
              <Form.File.Label data-browse="Browse">
                <i className="fa fa-upload"></i> {customFileName || 'Choose File'}
              </Form.File.Label>
              <Form.Control.Feedback type="invalid">
                Please select a file.
              </Form.Control.Feedback>
              <a href='/sample/schedule_vin_upload_sample.csv' download>Click to download sample file</a>

            </Form.File>
          </Form.Group>

          <Form.Group as={Col} md="8" controlId="scheduleExpiry">
            <Form.Label>Schedule Expiry</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                required
                type="text"
                min="1"
                name="scheduleExpiryDays"
                max={365}
                placeholder="Days"
                minLength={1}
                maxLength={3}
                defaultValue={scheduleExpiryDays}
                pattern="^(?:[1-9]\d?|[12]\d{2}|3[0-5]\d|36[0-5])$"
                onKeyUp={(e) => this.onExpiryKeyPress(e)}
              />
              <InputGroup.Append className="expiry-text">
                <InputGroup.Text id="basic-addon2">
                  days from date of approval
                </InputGroup.Text>
              </InputGroup.Append>
              <Form.Control.Feedback type="invalid">
                Please select days within range from 1 to 365 only.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Form.Row>
      </>
    );
  }
}
 
export default UpdateParamBulk;
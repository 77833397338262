import React from "react";
import Moment from "react-moment";
import "moment-timezone";

const DateConverter = props => {
  let format = "MMM DD YYYY hh:mm:ss A";
  if (props.format) {
    format = props.format;
  }
  return <span>
    {props.date != null ? <Moment format={format}>{props.date}</Moment> : ""}
  </span>;
};

export default DateConverter;

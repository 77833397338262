import React from "react";
import { Button } from "react-bootstrap";
import { scheduleStatus, permissionModules } from "../../utill/config";

const ScheduleApproval = props => {
  const { row } = props;
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let showButton = true;

  // if (currentUser && currentUser.userType && (currentUser.userType === "ADMIN" || currentUser.userType === "SCHEDULE_ADMIN")) {
  //   showButton = true;
  // } else 
  if (
    currentUser &&
    currentUser.userType &&
    currentUser.userType === "SCHEDULE_CREATOR_EXECUTOR" &&
    permissionModules[currentUser.userType].schedule.includes("withdraw")
    ) {
    showButton =
      currentUser &&
      currentUser.userId &&
      currentUser.userId === row.createdBy
        ? true
        : false;
  }

  const updateStatus = (row, status) => {
    props.confirmDelete({ row, status });
  };

  return (
    <span>
      {row.status &&
      (row.status === "IN_PROGRESS" || row.status === "REJECTED" || row.status === "COMPLETED") ? (
        <>
          <p className="mb-1">{row.updaterName}</p>
         
        </>
      ) : (
        <>
          {currentUser &&
          currentUser.userType &&
          permissionModules[currentUser.userType].schedule.includes("approve") &&
          row.status === "APPROVAL_PENDING" &&
          (row.cancelled + row.completed + row.inProgress + row.scheduled)>0 ? (
            <>
              <Button
                className="mr-2 action-btn"
                variant="success"
                size="sm"
                onClick={() => updateStatus(row, "APPROVED")}
              >
                <i className="fa fa-check action-btn-icon" aria-hidden="true">
                  &nbsp;
                </i>
                Approve
              </Button>

              <Button
                className="mr-2 action-btn"
                variant="warning"
                size="sm"
                onClick={() => updateStatus(row, "WITHDRAW")}
              >
                <i className="fa fa-rotate-left action-btn-icon" aria-hidden="true">
                  &nbsp;
                </i>
                Withdraw
              </Button>

              <Button
                className="mr-2 "
                variant="outline-danger"
                size="sm"
                onClick={() => updateStatus(row, "REJECTED")}
              >
                <i className="fa fa-times action-btn-icon" aria-hidden="true">
                  &nbsp;
                </i>
                Reject
              </Button>
            </>
          ) : null}
      
        </>
      )}
      {row.status === "WITHDRAW" ? (
        <>
          <p className="mb-1">{row.updaterName}</p>
          <p className="mb-1">{row.updaterEmail}</p>
        </>
      ) : null}
    </span>
  );
};

export default ScheduleApproval;

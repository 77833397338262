import React from "react";
import "./createButton.scss";

function CreateButton(props) {
  return (
    <button className="btn btn-success create-button" {...props}>
      <i className="fa fa-plus-circle"></i>
      <div className="name">{props.displaytext}</div>
    </button>
  );
}

export default CreateButton;

import React, { Component } from 'react';
import { Modal, Button, Spinner, Form, Col } from 'react-bootstrap';
import { syncEcu } from '../../utill/api/syncApi';
import InlineAlert from '../../shared/notificationAlert/inlineAlert';

class EcuMasterSyncModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      invalidFileExtension: null,
      isConfirmed: false
    }
  }

  toggleConfirmCheck = () => {
    this.setState(oldState => ({
      isConfirmed: !oldState.isConfirmed
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      file
    } = event.target;
    if (form.checkValidity()) {
      this.setState({ disable: true });
      const formData = new FormData();
      formData.append("file", file.files[0]);
      this.syncEcuMaster(formData);
    }
    this.setState({ validated: true });
  }

  syncEcuMaster = (file) => {
    syncEcu(file)
      .then(res => {
        if (res.status === 200) {
          this.props.getEcuMasterLog(0);
          res.successCode = "xmlFileUploadSuccess";
          // this.props.onHide();
          this.props.showModalSuccess(res);
          this.setState({ disable: false, isConfirmed: false, validated: false });
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  }

  render() {
    const {
      validated,
      isConfirmed,
      disable
    } = this.state;
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          backdrop={'static'}
          keyboard={false}
          >
          <Modal.Header closeButton>
            <Modal.Title>Ecu Master Sync</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Boolean(this.state.error) ? (
              <InlineAlert error={this.state.error} />
            ) : null}
            <Form
              noValidate
              encType="multipart/form-data"
              validated={validated}
              onSubmit={this.handleSubmit}
            >
              <Form.Row>
                <Form.Group
                  as={Col}
                  md="12"
                  controlId="validationCustomUsername"
                >
                  <Form.Label>Select XML File</Form.Label>
                  <Form.Control type="file" name="file" required accept="text/xml" />
                  <Form.Control.Feedback type="invalid">
                    Please choose File.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              Syncing ECU Master XML will replace old master and may affect schedules that are in progress. <br />
              This action cannot be undone. Please proceed with caution. <br /><br />
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox" value=""
                  checked={isConfirmed}
                  id="confirm"
                  onChange={this.toggleConfirmCheck}
                />
                <label className="form-check-label" for="confirm">
                  I confirm
                </label>
              </div>
              <div className="text-right mt-3">
                <Button className="mr-2" variant="primary" onClick={this.props.onHide} disabled={disable}>
                  <i className="fa fa-times" aria-hidden="true">&nbsp;</i>
                  Close
                </Button>
                <Button type="submit" variant="danger" disabled={disable || !isConfirmed}>
                  {disable ?
                    <Spinner animation="border" variant="light" size="sm" role="status" />
                    : <i className="fa fa-upload" aria-hidden="true">&nbsp;</i>
                  }
                  Upload
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default EcuMasterSyncModal;
import React, { Component } from "react";

class Profile extends Component {
  state = {};
  render() {
    return (
      <>
        <h1>Profile</h1>
      </>
    );
  }
}

export default Profile;

import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CreateButton from "../../shared/customButtons/createButton/createButton";
import CustomDataTable from "../../shared/customDataTable/customDataTable";
import { fileApi, deleteFileApi, fileSearchApi } from "../../utill/api/filesApi";
import AddFileModal from "./FileAddModal";
import DateConverter from "../../shared/dateConverter/dateConverter";
import DeleteFile from "./DeleteFile";
import "./Files.scss";
import ConfirmDeleteModal from "../../shared/modals/confirmDeleteModal";
import { scheduleType, permissionModules } from "../../utill/config";
import ToastAlert from "../../shared/notificationAlert/toastAlert";
import ServerSearch from "../../shared/server-search/server-serach";

class Files extends Component {
  columns = [
    {
      name: "#",
      sortable: true,
      minWidth: "60px",
      maxWidth: "50px",
      cell: row => row.index
    },
    {
      name: "Name",
      selector: "fileName",
      sortable: true,
      maxWidth: "700px",
      minWidth: "300px",
      cell: row => <div>{row.fileName}</div>
    },
    {
      name: "ECU Group",
      selector: "ecuGroup",
      sortable: true,
      minWidth: "100px",
      cell: row => <span>{row.ecuGroup}</span>
    },
    {
      name: "ECU Name",
      selector: "ecuName",
      sortable: true,
      minWidth: "100px",
      cell: row => <span>{row.ecuName}</span>
    },
    {
      name: "File Type",
      selector: "fileType",
      sortable: true,
      maxWidth: "100px",
      // cell: row => <span>{fileType[row.fileType]}</span>
      cell: row => <span>{row.fileType}</span>
    },
    {
      name: "Schedule Type",
      selector: "scheduleType",
      sortable: true,
      minWidth: "150px",
      // cell: row => <span>{fileType[row.fileType]}</span>
      cell: row => <span>{scheduleType[row.scheduleType]}</span>
    },
    {
      name: "Uploaded By",
      selector: "creatorName",
      sortable: true,
      minWidth: "150px",
      cell: row => <span>{row.creatorName}</span>
    },
    {
      name: "Uploaded On",
      selector: "createdAt",
      sortable: true,
      minWidth: "180px",
      cell: row => <DateConverter date={row.createdAt} />
    },
    {
      name: "Action",
      selector: "fileName",
      minWidth: "70px",
      cell: row => <DeleteFile row={row} confirmDelete={this.confirmDelete} />
    }
  ];

  searchDropdownlist = [
    { displayText: "File Name", key: "fileName" },
    { displayText: "ECU Group", key: "ecuGroup" },
    { displayText: "ECU Name", key: "ecuName" },
    { displayText: "Uploaded By", key: "uploadedBy" }
  ];

  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  currentPage = 0;

  constructor() {
    super();
    this.state = {
      fileData: [],
      searchKey: '',
      searchValue: '',
      isLoading: true,
      showModal: false,
      error: null,
      showDeleteModal: false,
      disableConfirmButton: false,
      deleteData: {},
      resetPage: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.getFiles = this.getFiles.bind(this);
  }
  componentDidMount() {
    /**
     * This is not called here because the useEffect Hook in the customDataTable 
     * component calls it automatically in the initial rendering.
     * This methodology is followed across all class components using customDataTable.
     */
    // this.getFiles();
    if (this.currentUser == null || this.currentUser === undefined) {
      localStorage.clear();
      this.props.history.push('/login');
    }
  }
  /**
   * To get files
   */
  getFiles(page) {
    fileApi(page)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setIndexNumber(res, page);
            } else this.setState({ isLoading: false });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }

  getSearchedFiles(page) {
    const { searchKey, searchValue } = this.state;
    fileSearchApi(page, searchKey, searchValue)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setIndexNumber(res, page);
            } else this.setState({ isLoading: false });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }
  /**
   * To set index for each row
   */
  setIndexNumber = (data, page) => {
    if (page == 0) {
      this.setState({ fileData: [] });
    }
    data.map((row, index) => {
      return (row.index = this.state.fileData.length + index + 1);
    });
    if (page == 0) {
      this.setState({
        fileData: data,
        isLoading: false
      })
    } else {
      this.setState(prevState => ({
        fileData: [...prevState.fileData, ...data],
        isLoading: false
      }));
    }
  };

  deleteFile = () => {
    this.setState({ disableConfirmButton: true });
    deleteFileApi(this.state.deleteData.id)
      .then(res => {
        if (res.status === 200) {
          res.successCode = "fileDelete";
          this.setState({
            error: res,
            showDeleteModal: false,
            disableConfirmButton: false
          });
          this.getFiles(0);
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              deleteData: {},
              showDeleteModal: false,
              disableConfirmButton: false
            });
          });
          // this.setState({ deleteData: {} });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
    // this.setState({ showDeleteModal: false });
  };

  toggleModal() {
    this.setState(oldState => ({
      showModal: !oldState.showModal
    }));
  }

  confirmDelete = data => {
    if (!this.state.showDeleteModal) {
      this.setState({ showDeleteModal: true, deleteData: data });
    } else if (this.state.showDeleteModal) {
      this.setState({ showDeleteModal: false, deleteData: {} });
    }
  };

  handleSearch = async (key, value) => {
    if (key && value) {
      localStorage.setItem("fileSearchKey", key);
      localStorage.setItem("fileSearchValue", value);
      this.setState({ searchKey: key, searchValue: value, isLoading: true });
      const apiPromice = await fileSearchApi(0, key, value);
      const res = await apiPromice.json();
      if (res.error && res.status !== 200) {
        this.setState({ error: res, fileData: [], isLoading: false });
      } else {
        this.setIndexNumber(res, 0);
      }
    } else {
      this.setState(prevState => ({
        resetPage: !prevState.resetPage,
        searchKey: '', searchValue: ''
      }), () => this.getFiles(0));
    }
  };

  errorHandled = () => {
    this.setState({ error: null });
  };

  showModalSuccess = (e) => {
    this.setState({ error: e });
  };

  loadNextPage = (page, currentPage) => {
    this.currentPage = currentPage;
    const { searchKey, searchValue } = this.state;
    if (searchKey !== '' && searchValue !== '') this.getSearchedFiles(page)
    else this.getFiles(page);
  };

  render() {
    return (
      <>
        {/* Toast Alert */}
        {Boolean(this.state.error) ? (
          <ToastAlert
            show={Boolean(this.state.error)}
            onClose={this.errorHandled}
            error={this.state.error}
          />
        ) : null}
        {/* Page Content starts */}
        <Container fluid>
          <Row className="page-title">
            <Col className="col-4">
              <h1>Files</h1>
            </Col>
            <Col className="col-5">
              <ServerSearch
                dropdown
                files
                list={this.searchDropdownlist}
                search={this.handleSearch}
              />
              {/* <Search
                dropdown
                list={this.searchDropdownlist}
                data={this.state.fileData}
                search={this.handleSearch}
              /> */}
            </Col>
            <Col className="col-3 text-right">
              {this.currentUser &&
                this.currentUser.userType &&
                permissionModules[this.currentUser.userType].file.includes(
                  "create"
                ) ? (
                  <CreateButton
                    displaytext="NEW FILE"
                    onClick={() => this.setState({ showModal: true })}
                  />
                ) : null}
            </Col>
          </Row>
          <Row noGutters>
            <Col className="data-table">
              <CustomDataTable
                filesTable
                tableColumns={this.columns}
                data={this.state.fileData}
                loading={this.state.isLoading}
                loadNextPage={this.loadNextPage}
                currentPage={this.currentPage}
                resetPage={this.state.resetPage}
              />
            </Col>
          </Row>
        </Container>
        {this.state.showModal ? (
          <AddFileModal
            show={this.state.showModal}
            getFiles={this.getFiles}
            onHide={this.toggleModal}
            showModalSuccess={this.showModalSuccess}
          />
        ) : null}
        <ConfirmDeleteModal
          show={this.state.showDeleteModal}
          disable={this.state.disableConfirmButton}
          onHide={this.confirmDelete}
          update={this.deleteFile}
          action={"delete"}
        />
      </>
    );
  }
}

export default Files;

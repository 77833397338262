import React from "react";
import { Button } from "react-bootstrap";
import { permissionModules } from "../../utill/config";

const EditSchedule = props => {
  const { schedule } = props;
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let showButton = true;

  // if (currentUser && currentUser.userType && currentUser.userType === "ADMIN") {
  //   showButton = true;
  // } else
  if (
    currentUser &&
    currentUser.userType &&
    currentUser.userType === "SCHEDULE_CREATOR_EXECUTOR" &&
    (permissionModules[currentUser.userType].schedule.includes("edit") ||
    permissionModules[currentUser.userType].schedule.includes("delete"))
    ) {
    showButton =
      currentUser &&
      currentUser.userId &&
      currentUser.userId === schedule.createdBy
        ? true
        : false;
  }

  return (
    <>
      {/* <span>
        {schedule.status === "WITHDRAW" &&
        showButton &&
        permissionModules[currentUser.userType].schedule.includes("edit") &&
        currentUser.group != 2 &&
        (schedule.type !== "readParameters" || schedule.type !== "updateParameters")? (
          <Button
            className="action-btn btn btn-primary approve-btn mr-2"
            variant="primary"
            size="sm"
            onClick={() => props.edit(schedule)}
          >
            <i className="fa fa-pencil" aria-hidden="true">
              &nbsp;
            </i>
            Edit
          </Button>
        ) : null}
      </span> */}
      <span>
        {currentUser &&
        currentUser.userType &&
        showButton &&
        permissionModules[currentUser.userType].schedule.includes("delete") &&
        (schedule.status === "APPROVAL_PENDING" || schedule.status === "WITHDRAW") ? (
          <>
            <Button
             
              variant="outline-danger"
              size="sm"
              onClick={() => props.delete({row: schedule, status: "DELETE_SCHEDULE"})}
            >
              <i className="fa fa-trash-o action-button-icon">&nbsp;</i>
              Delete
            </Button>
          </>
        ) : null}
      </span>
    </>
  );
};

export default EditSchedule;

import apiHelper from "./apiHelper";

export const loginApi = (payload) => {
  return apiHelper({
    url: `${process.env.REACT_APP_DOMAIN}/authentication/token`,
    method: "POST",
    body: payload
  })
};

export const currentUserApi = (tokenType, tokenId) => {
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + tokenId},
    url: `${process.env.REACT_APP_DOMAIN}/users/loggedin/info`,
    method: "GET"
  })
};

export const logoutApi = (tokenId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/users/loggedin/info`,
    method: "DELETE"
  })
};

export const changePasswordApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/users/update-password`,
    method: "PUT",
    body: payload
  })
};
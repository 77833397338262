import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { getCancelStatusApi, recordCancelActivityApi } from '../../utill/api/schedulesApi';
import InlineAlert from "../../shared/notificationAlert/inlineAlert";

function CancelStatusModal(props) {
  
  const { vehicle } = props;
  const serialNumber = vehicle && vehicle.row && vehicle.row.serialNumber ? vehicle.row.serialNumber : '';
  const [status, setStatus] = useState(-1);
  const [time, setTime] = useState(-1);
  const [error, setError] = useState(null);
  const [isPlaying, setPlaying] = useState(true);
  const [disable, setDisable] = useState(true);

  // Similar to componentDidMount and componentDidUpdate:
  // useEffect(() => {
  //   console.log('hook invoked')
  //   setStatus(-1);
  //   setTime(-1);
  //   setError(null);
  //   setPlaying(true);
  //   setDisable(true);
  // }, [serialNumber]);

  const onTimerComplete = (isManual) => {
    setPlaying(false);
    setTimeout(function(){
      recordActivity();
    }, 1000);
    return [false];
  };

  const renderTime = value => {
    if (value % 5 === 0) checkTime(value);
    return <h5 className="m-0">{value}</h5>;
  }

  const checkTime = (value) => {
    if (time !== value) {
      setTime(value);
      getCancelStatus();
    }
  };

  const getCancelStatus = () => {
    getCancelStatusApi(serialNumber)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            setError(res);
          });
        } else {
          res.json().then(res => {
            checkStatus(res.status);
          });
        }
      })
      .catch(e => {
        setError(e);
      });
  };

  const checkStatus = (status) => {
    setStatus(status)
    if (props.show && (status == 2 || status == 1)) {
      setPlaying(false);
      setDisable(false)
    }
  };

  const recordActivity = () => {
    recordCancelActivityApi(serialNumber)
      .then(res => {
        if (res.status === 200) {
          setDisable(false);
        } else if (res.status !== 200) {
          res.json().then(res => {
            setError(res);
            setDisable(false);
          })
        }
      })
      .catch(e => {
        setError(e);
        setDisable(false);
      });
  }

  const resetAndCloseModal = () => {
    setStatus(-1);
    setTime(-1);
    setError(null);
    setPlaying(true);
    setDisable(true);
    props.onHide();
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop={'static'}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title className="text-center width100" id="contained-modal-title-vcenter">
            Waiting for Cancel Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* error */}
          {error ? (
            <InlineAlert error={error} />
          ) : null}
          {/* Timer while fetching status */}
          {isPlaying ?
          <>
            <CountdownCircleTimer
              isPlaying={isPlaying}
              size={120}
              strokeWidth={10}
              durationSeconds={180}
              onComplete={() => onTimerComplete(false)}
              renderTime={renderTime}
              colors={[
                ['#004777', .33],
                ['#F7B801', .33],
                ['#A30000']
              ]}
            />
            <p className="text-center mt-4 px-4">
              Please wait while we fetch the cancel status.
              Do not the refresh the page or there may be inconsistency in the data.
            </p>
          </>
          : null }
          {/* message after Cancel success */}
          {!isPlaying && status == 2 ?
          <>
            <p className="text-center m-0 px-4">
            <i class="fa fa-check-circle-o text-success" style={{fontSize: 180 + 'px'}}></i> <br/>
              Cancel request completed.
            </p>
          </>
          : null }
          {/* message when device is busy */}
          {!isPlaying && status == 1 ?
          <>
            <p className="text-center m-0 px-4">
            <i class="fa fa-hourglass-half text-warning mb-4" style={{fontSize: 135 + 'px'}}></i> <br/>
              Device is busy at the moment. Please try again after some time.
            </p>
          </>
          : null }
          {/* message when no response from the device */}
          {!isPlaying && status == 0 ?
          <>
            <p className="text-center m-0 px-4">
            <i class="fa fa-ban text-danger" style={{fontSize: 180 + 'px'}}></i> <br/>
              There is no response from device. Please try again after some time.
            </p>
          </>
          : null }
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'center'}}>
          <Button variant="primary" onClick={() => resetAndCloseModal()} disabled={disable}>
            {props.disable ?
              <Spinner animation="border" variant="light" size="sm" role="status" />
              : <i className="fa fa-check" aria-hidden="true">&nbsp;</i>
            }
            Ok, Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CancelStatusModal;

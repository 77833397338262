import React, { Component } from 'react';
import { InputGroup, DropdownButton, Dropdown, FormControl } from 'react-bootstrap';

class ServerSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.list,
      title: {},
      typeValue: ""
    }
  }

  componentDidMount() {
    if (this.props.dropdown) {
      if (this.props.schedules) {
        const scheduleSearchKey = localStorage.getItem("scheduleSearchKey");
        if (scheduleSearchKey) {
          this.state.items.map((item, index) => {
            if (item.key === scheduleSearchKey) {
              this.setState({ title: this.state.items[index] });
            }
          });
        } else {
          this.setState({ title: this.state.items[0] });
        }
        const scheduleSearchValue = localStorage.getItem("scheduleSearchValue");
        if (scheduleSearchValue) {
          this.setState({ typeValue: scheduleSearchValue });
        }
      } else if (this.props.files) {
        const fileSearchKey = localStorage.getItem("fileSearchKey");
        if (fileSearchKey) {
          this.state.items.map((item, index) => {
            if (item.key === fileSearchKey) {
              this.setState({ title: this.state.items[index] });
            }
          });
        } else {
          this.setState({ title: this.state.items[0] });
        }
        const fileSearchValue = localStorage.getItem("fileSearchValue");
        if (fileSearchValue) {
          this.setState({ typeValue: fileSearchValue });
        }
      } else {
        this.setState({ title: this.state.items[0] });
      }
      // this.title = this.state.items[0];
    }
  }

  onTypeSelect = index => {
    const title = {};
    if (this.props.scheduleDetail) {
      title.displayText = 'VIN';
      title.key = 'vin';
    } else {
      title.displayText = this.state.items[index].displayText;
      title.key = this.state.items[index].key;
    }
    this.setState({ title: title },
      () => this.onSearch(this.state.typeValue)
    );
    this.focus();
  };

  onSearch = typeValue => {
    typeValue = typeValue.trim();
    this.setState({ typeValue },
      () => this.props.search(this.state.title.key, typeValue)
    );
  }

  focus() {
    this.textInput.focus();
  }

  render() {
    const {
      title,
      items
    } = this.state;
    return (
      <>
        <InputGroup className="search-box">
          {this.props.dropdown ? (
            <DropdownButton
              as={InputGroup.Prepend}
              variant="outline-primary"
              title={title.displayText ? title.displayText : "Select Type"}
              id="input-group-dropdown"
              onSelect={e => this.onTypeSelect(e)}
              style={{width: '215px'}}
            >
              {items.map((item, index) => (
                <Dropdown.Item eventKey={index} key={index}>
                  {item.displayText}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          ) : null}
          <FormControl
            ref={(input) => { this.textInput = input; }}
            placeholder={this.props.scheduleDetail ? "Search by VIN." : "Search is case sensitive."}
            aria-label="Search any keyword"
            value={this.state.typeValue}
            onChange={e => { this.onSearch(e.target.value) }}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <i className="fa fa-search"></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {(title.key && title.key === 'vin') || this.props.scheduleDetail ?
          <small className="text-secondary pl-3" style={{position: 'absolute', fontStyle: 'italic'}}>
            <fa className="fa fa-info-circle"></fa> Partial VIN search is allowed only for last 5 characters and is case sensitive.
          </small>
        : null}
      </>
    );
  }
}

export default ServerSearch;
import React, { useState, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { permissionModules } from "../../utill/config";

const ScheduleBulkTrigger = (props) => {
  const { scheduleDetail } = props;

  const [schedule, setShedule] = useState([]);

  useEffect(() => {
    setShedule(scheduleDetail);
  }, [scheduleDetail]);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let isPermitted = true;

  const updateStatus = (row, scope) => {
  
    props.confirmDelete({ row, scope });
  };

  if (
    currentUser &&
    currentUser.userType &&
    currentUser.userType === "SCHEDULE_CREATOR_EXECUTOR" &&
    permissionModules[currentUser.userType].schedule.includes("triggerFlash")
  ) {
    isPermitted =
      currentUser &&
      currentUser.userId &&
      currentUser.userId === schedule.createdBy
        ? true
        : false;
  }

  return (
    <span>
      {/* Flash Ecu Trigger Button and conditions */}
      {currentUser &&
      currentUser.userType &&
      isPermitted &&
      permissionModules[currentUser.userType].schedule.includes(
        "triggerFlash"
      ) &&
      schedule.status === "IN_PROGRESS" &&
      schedule.type === "flashEcu" ? (
        <>
          {
            <OverlayTrigger
              placement="left"
              //delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="bulk-tooltip">
                Trigger Flash for all the vehicles if conditions matches:
               <br/>
              1. Ignition must be: ON <br/>
              2. Engine must be: OFF <br/>
              3. Vehicle must be: NOT RUNNING <br/>
              4. Live Communication (Last communication within 5 mins)  
              </Tooltip>}
            >
              <Button
                className="ml-2"
                variant="primary"
                onClick={(e) =>{ 
                  e.target.blur();
                  updateStatus(scheduleDetail, "bulkTrigger")}}
              >
                <i className="fa fa-bolt" aria-hidden="true"></i>&nbsp; Trigger
                All
              </Button>
            </OverlayTrigger>
          }
        </>
      ) : null}
    </span>
  );
};

export default ScheduleBulkTrigger;

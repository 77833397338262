import React from 'react';
import { permissionModules } from "../../utill/config";
import { Button } from 'react-bootstrap';

const UserActions = (props) => {
  const { row } = props;
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  return (
    <span>
      {/* Edit User Button */}
      {currentUser &&
      currentUser.userType &&
      permissionModules[currentUser.userType].admin.includes("userEdit")  ? (
        <Button
          className="mr-2 action-btn"
          variant="outline-primary"
          size="sm"
          onClick={() => props.edit(row)}
          >
          <i className="fa fa-pencil action-button-icon" aria-hidden="true"></i>&nbsp;
          Edit
        </Button>
      ) : null}
      {/* Reset Password Button */}
      {currentUser &&
      currentUser.userType &&
      permissionModules[currentUser.userType].admin.includes("userUpdate") &&
      currentUser.userId !== row.userId  ? (
        <Button
          className="mr-2 action-btn"
          variant="outline-info"
          size="sm"
          onClick={() => props.resetPassword(row)}
          >
          <i className="fa fa-key action-button-icon" aria-hidden="true"></i>&nbsp;
          Reset
        </Button>
      ) : null}
      {/* Delete User Button */}
      {currentUser &&
      currentUser.userType &&
      permissionModules[currentUser.userType].admin.includes("userDelete") &&
      currentUser.userId !== row.userId  ? (
        <Button
          className="mr-2 action-btn"
          variant="outline-danger"
          size="sm"
          onClick={() => props.confirmDelete(row)}
          >
          <i className="fa fa-trash-o action-button-icon" aria-hidden="true"></i>&nbsp;
          Delete
        </Button>
      ) : null}
    </span>
  );
}

export default UserActions;
import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner, Tabs, Tab, ListGroup } from "react-bootstrap";
import DateConverter from "../../shared/dateConverter/dateConverter";
import SecondsConverter from "../../shared/secondsConverter/secondsConverter";
import { scheduleType, vehicleStatus } from "../../utill/config";

const ActivityModal = props => {
  const { data, vehicle } = props;
  const [list, setList] = useState([]);
  useEffect(() => { setList(data)}, [data] )
  // const [key, setKey] = useState('thisSchedule');
  return (
    <>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Vehicle Activity
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            activeKey={props.activeKey}
            id="history-tab"
            onSelect={(k) => props.changeActiveKey(k)}
            className="vehicle-activity-tabs mb-3">
            {/* Tab for current schedule */}
            <Tab eventKey="thisSchedule" title="This Schedule">
              {props.isLoading ? (
                <>
                <div className="text-center p-4 align-items-center">
                  <Spinner animation="border" variant="primary" size="lg" />
                  &nbsp;<span>Loading...</span>
                </div>
                </>
              ) : (
                <>
                  {data.length !== 0 ? (
                    <>
                    <div className="row">
                      <div className="col-6 pl-4">
                        <i className="fa fa-download d-inline"></i>
                        <span className="pl-2">Download Time</span>
                        <div className="pl-4">
                          <span className="bold">
                            {vehicle.fileDownloadTimeInSeconds ?
                            <>
                              <SecondsConverter seconds={vehicle.fileDownloadTimeInSeconds} />
                            </> : '----------'}
                          </span>
                        </div>
                      </div>
                      <div className="col-6">
                        <i className="fa fa-bolt d-inline"></i>
                        <span className="pl-2">Processing Time</span>
                        <div className="pl-3">
                          <span className="bold">
                            {vehicle.flashDownloadTimeInSeconds ?
                            <>
                              <SecondsConverter seconds={vehicle.flashDownloadTimeInSeconds} />
                            </> : '----------'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <ul>
                      {list.map((activity, index) => {
                        return (
                          <li key={index} className="activity">
                            <div className="row mx-0">
                              <div className="col-4 px-0">
                                <span><DateConverter date={activity.eventTime} format="MMM DD YYYY hh:mm:ss A" /></span>
                                
                              </div>
                              <div className="col-8 px-0">
                                <span className="mr-2">
                                {activity.typeId === 1 ?
                                  <i className="fa fa-cloud" aria-hidden="true"></i> :
                                  <i className="fa fa-microchip" aria-hidden="true"></i>
                                }
                                </span>
                                {
                                 
                                   <span>{activity.value}</span>
                                }
                                
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    </>
                  ) : (
                    <div className="text-center p-4">
                      <h5>No Activity</h5>
                    </div>
                  )}
                </>
              )}
              {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
            </Tab>
            {/* Tab for previous schedules */}
            <Tab eventKey="previousSchedule" title="Previous Schedules">
              {props.isLoading ? (
                <>
                  <div className="text-center p-4 align-items-center">
                    <Spinner animation="border" variant="primary" size="lg" />
                    &nbsp;<span>Loading...</span>
                  </div>
                </>
                ) : (
                <>
                  {data.length > 1 ? (
                    <>
                      <ListGroup className="activity-schedule-list">
                        {props.data.map((schedule, index) => {
                          return index !== 0 ?
                            <ListGroup.Item key={index}>
                              <span className="name">{schedule.name}</span>
                              <div>
                                <label className="mb-0 mr-2 ml-0">Schedule Type:</label>
                                <label className="mb-0 mr-2">{scheduleType[schedule.type]}</label>
                              </div>
                              <div>
                                <label className="mb-0 mr-2 ml-0">Status:</label>
                                <label className="mb-0 mr-2">{vehicleStatus[schedule.status] || schedule.status}</label>
                              </div>
                              <div>
                                <label className="mb-0 mr-2 ml-0">ECU Group/ECU Name:</label>
                                <label className="mb-0 mr-2">{schedule.ecuGroup} / {schedule.ecuName}</label>
                              </div>
                              <div>
                                <label className="mb-0 mr-2 ml-0">Created By:</label>
                                <label className="mb-0 mr-2">{schedule.creatorName}</label>
                              </div>
                              <div>
                                <label className="mb-0 mr-2 ml-0">Approved/Rejected By:</label>
                                <label className="mb-0 mr-2">{schedule.updaterName}</label>
                              </div>
                              <div>
                                <label className="mb-0 mr-2 ml-0">Created On:</label>
                                <label className="mb-0 mr-2"><DateConverter date={schedule.createdAt} /></label>
                              </div>
                            </ListGroup.Item>
                          : null
                        })}
                      </ListGroup>
                    </>
                  ) : (
                    <div className="text-center p-4">
                      <h5>No Records Found.</h5>
                    </div>
                  )}
                </>
              )}
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActivityModal;

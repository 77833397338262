import React from "react";
import { loginApi, currentUserApi } from "../../utill/api/loginApi";
import { Card, Button, Form, Spinner } from "react-bootstrap";
import "./Login.scss";
import { Redirect } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import InlineAlert from "../../shared/notificationAlert/inlineAlert";

class LoginForm extends React.Component {
  constructor() {
    super();
    this.state = {
      showHeading: false,
      loggedIn: false,
      error: {},
      isLoading: false,
      currentUser: {}
    };
    this.toggleButtonDisable = this.toggleButtonDisable.bind(this);
  }

  componentDidMount() {
    if (localStorage.token || localStorage.currentUser) {
      this.setState({ loggedIn: true });
    } else {
      this.setState({ loggedIn: false });
    }
  }

  login = e => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      this.toggleButtonDisable();
      const { password, emailId } = e.target;
      const payload = {
        password: password.value,
        emailId: emailId.value.trim()
      };
      loginApi(payload)
        .then(res => res.json())
        .then(data => {
          if (data.token) {
            // let base64Token = btoa(data.token);
            let token = data.token;
            localStorage.setItem("token", token);
            localStorage.setItem("tokenType", data.tokenType);
            localStorage.setItem("encodedToken", token);
            this.getCurrentUser(data.tokenType, data.token);
          } else if (data.status !== 200) {
            this.setState({ error: data, isLoading: false });
          }
        })
        .catch(e => {
          this.setState({ error: e, isLoading: false });
        });
    }
    this.setState({ validated: true });
  };

  getCurrentUser = (tokenType, token) => {
    currentUserApi(tokenType, token)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            localStorage.setItem("currentUser", JSON.stringify(res));
            this.setState({ currentUser: res, loggedIn: true, isLoading: false });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e, isLoading: false });
      });
  };

  toggleButtonDisable() {
    this.setState(oldState => ({
      isLoading: !oldState.showModal
    }));
  }

  render() {
    if (this.state.loggedIn) {
      return <Redirect to="/schedules" />;
    }
    return (
      <div className="login-container">
        {this.state.showHeading ? <h1>Login here</h1> : null}
        <div className="form-container relative">
          <Card.Body className="login-form">
            <p className="text-center">
              <img
                src={logo}
                className="d-inline-block align-top"
                alt="Ashok Leyland"
              />
            </p>
            <h3>Log In</h3>
            <Form
              validated={this.state.validated}
              noValidate
              onSubmit={this.login}
            >
              <Form.Group
                className="relative"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-icon">
                  <i className="fa fa-envelope fa-md"></i>
                </Form.Label>
                <Form.Control
                  name="emailId"
                  required
                  size="sm"
                  type="text"
                  placeholder="Email"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="relative"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-icon">
                  <i className="fa fa-lock fa-lg"></i>
                </Form.Label>
                <Form.Control
                  name="password"
                  required
                  size="sm"
                  type="password"
                  placeholder="Password"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter password.
                </Form.Control.Feedback>
              </Form.Group>
              {this.state.error.message ? (
                <InlineAlert error={this.state.error} />
              ) : null}
              <Button
                className="col primary-bg"
                variant="primary"
                type="submit"
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                    role="status"
                  />
                ) : null}
                {this.state.isLoading ? "Logging In..." : "Login"}
              </Button>
            </Form>
          </Card.Body>
        </div>
      </div>
    );
  }
}

export default LoginForm;

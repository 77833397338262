var config = {
  scheduleStatus: {
    "APPROVAL_PENDING": "Pending for Approval",
    "IN_PROGRESS": "In Progress",
    "APPROVED": "Approved",
    "REJECTED": "Rejected",
    "ELAPSED": "Elapsed",
    "WITHDRAW": "Withdrawn",
    "COMPLETED": "Completed",
  }, 
  scheduleStatusColorCode :{
    "APPROVAL_PENDING": "bold text-primary",
    "IN_PROGRESS": "bold text-warning",
    "APPROVED": "bold text-info",
    "REJECTED": "bold text-danger",
    "ELAPSED": "bold text-danger",
    "WITHDRAW": "bold text-danger",
    "COMPLETED":"bold text-success"
  }, 
  scheduleStatusColorCode_bkp :{
    "APPROVAL_PENDING": "p-1 bg-primary text-white",
    "IN_PROGRESS": "p-1 bg-warning text-white",
    "APPROVED": "p-1 bg-info text-white",
    "REJECTED": "p-1 bg-danger text-white",
    "ELAPSED": "p-1 bg-danger text-white",
    "WITHDRAW": "p-1 bg-dark text-white",
    "COMPLETED":"p-1 bg-success text-white"
  }, 
  vehicleStatus: {
    "SUCCESS": "Success",
    "FAILED": "Failed",
    "SCHEDULE": "Scheduled",
    "IN_PROGRESS": "In Progress",
    "REJECTED": "Schedule: Rejected",
    "ELAPSED": "Schedule: Elapsed",
    "WITHDRAW": "Schedule: Withdrawn",
    "CANCELLED": "Cancelled",
    "CLEAR_DTC_COMPLETED": "Clear DTC: Successful",
    "CLEAR_DTC_FAILED": "Clear DTC: Failed",
    "CLEAR_DTC_REQ": "Clear DTC: Acknowledged",
    "ERASE_DTC_REQ": "Erase DTC: Acknowledged",
    "DELETE_FILE_COMPLETED": "File Delete: Successful",
    "DELETE_FILE_FAILED": "File Delete: Failed",
    "DELETE_FILE_REQ": "File Delete: Acknowledged",
    "FILE_DWLD_INIT": "File Download: Initiated",
    "FILE_DWLD_REQ": "File Download: Acknowledged",
    "FILE_DWLD_IN_PROGRESS": "File Download: In Progress",
    "FILE_DWLD_COMPLETED": "File Download: Successful",
    "FILE_DWLD_FAILED": "File Download: Failed",
    "FILE_DWLD_TKN_INVD": "File Download: Invalid Token",
    "READ_VERSION": "Read Version: Initiated",
    "FILE_DELETED": "File Deleted (Cancelled)",
    "READ_VERSION_COMPLETED": "Read Version: Successful",
    "READ_VERSION_FAILED": "Read Version: Failed",
    "FLASH_INIT": "Flash: Initiated",
    "FLASH_REQ": "Flash: Acknowledged",
    "FLASH_IN_PROGRESS": "Flash: In Progress",
    "FLASH_SUCCESS": "Flash: Successful",
    "FLASH_FAILED": "Flash: Failed",
    "FLASH_CANCEL_COMPLETED": "Flash: Cancelled",
    "FLASH_CANCEL_FAILED": "Flash Cancellation: Failed",
    "FLASH_CANCEL_REQ": "Flash Cancellation: Acknowledged",
    "FLASH_CANCEL_TKN_INVD": "Flash Cancellation: Invalid Token",
    "FLASH_ECU_COMPLETED": "Flash: Successful",
    "FLASH_COMPLETED": "Flash: Successful",
    "FLASH_REQ_TKN_INVD": "Flash: Invalid Token",
    "FREEZE_FRAME_REQ": "Read DTC with Snapshot: Acknowledged",
    "PARAMS_READ_REQ": "Read Parameters: Acknowledged",
    "READ_DTC_COMPLETED": "Read DTC: Successful",
    "READ_DTC_FAILED": "Read DTC: Failed",
    "READ_DTC_REQ": "Read DTC: Acknowledged",
    "READ_FREEZE_FRAME_COMPLETED": "Read DTC with Snapshot: Successful",
    "READ_FREEZE_FRAME_FAILED": "Read DTC with Snapshot: Failed",
    "READ_FREEZE_FRAME_REQ": "Read DTC with Snapshot: Acknowledged",
    "READ_PARAMETERS_COMPLETED": "Read Parameters: Successful",
    "READ_PARAMETERS_FAILED": "Read Parameters: Failed",
    "READ_PARAMETERS_REQ": "Read Parameters: Acknowledged",
    "REMOTE_DIAG_CONN_FAILED": "Live Remote Diagnostics: Connection Failed",
    "REMOTE_DIAG_REQ": "Live Remote Diagnostics: Acknowledged",
    "REMOTE_DIAG_TKN_INVD": "Live Remote Diagnostics: Invalid Token",
    "SERVR_ERR": "Server Error",
    "WRITE_PARAMETERS_INIT": "Updating Parameters: Initiated",
    "WRITE_PARAMETERS_COMPLETED": "Updating Parameters: Successful",
    "WRITE_PARAMETERS_FAILED": "Updating Parameters: Failed",
    "WRITE_PARAMETERS_REQ": "Updating Parameters: Acknowledged",
    "FILE_WRAPPING_FAILED": "File Wrapping: Failed",
    "FILE_WRAPPING": "File Wrapping: In Progress",
    "APPROVAL_PENDING": "Schedule Approval: Pending",
    "RETRY_REQ": "Retry: Requested"

  },
  statusColorCode: {
    "APPROVAL_PENDING": "info",
    "CANCEL": "error",
    "CANCEL_REQ": "info",
    "CLEAR_DTC": "info",
    "CLEAR_DTC_COMPLETED": "success",
    "CLEAR_DTC_FAILED": "error",
    "CLEAR_DTC_REQ": "inprogress",
    "DELETE_FILE": "info",
    "DELETE_FILE_COMPLETED": "success",
    "DELETE_FILE_FAILED": "error",
    "DELETE_FILE_REQ": "inprogress",
    "DEVICE_BUSY": "info",
    "DEVICE_FILE_DOWNLOAD": "info",
    "ECU_UNLOCK_KEY": "inprogress",
    "FILE_DELETED": "info",
    "FILE_DOWNLOAD": "inprogress",
    "FILE_DWLD_INIT": "inprogress",
    "FILE_DWLD_COMPLETED": "inprogress",
    "FILE_DWLD_FAILED": "error",
    "FILE_DWLD_REQ": "inprogress",
    "FILE_DWLD_TKN_INVD": "error",
    "FLASH_CANCEL_COMPLETED": "success",
    "FLASH_CANCEL_FAILED": "error",
    "FLASH_CANCEL_REQ": "inprogress",
    "FLASH_CANCEL_TKN_INVD": "error",
    "FLASH_COMPLETED": "success",
    "FLASH_INIT":"inprogress",
    "FLASH_ECU": "info",
    "FLASH_ECU_COMPLETED": "success",
    "FLASH_FAILED": "error",
    "FLASH_PROGRESS": "inprogress",
    "FLASH_IN_PROGRESS":"inprogress",
    "FLASH_REQ": "inprogress",
    "FLASH_REQ_TKN_INVD": "error",
    "NO_CANCEL_RESPONSE": "info",
    "READ_DTC": "info",
    "READ_DTC_COMPLETED": "success",
    "READ_DTC_FAILED": "error",
    "READ_DTC_REQ": "inprogress",
    "READ_FREEZE_FRAME": "info",
    "READ_FREEZE_FRAME_COMPLETED": "success",
    "READ_FREEZE_FRAME_FAILED": "error",
    "READ_FREEZE_FRAME_REQ": "inprogress",
    "READ_PARAMETERS": "info",
    "READ_PARAMETERS_COMPLETED": "success",
    "READ_PARAMETERS_FAILED": "error",
    "READ_PARAMETERS_REQ": "inprogress",
    "READ_VERSION": "inprogress",
    "READ_VERSION_COMPLETED": "inprogress",
    "READ_VERSION_FAILED": "error",
    "REMOTE_DIAG_CONN_FAILED": "error",
    "REMOTE_DIAG_REQ": "info",
    "REMOTE_DIAG_TKN_INVD": "error",
    "REJECTED": "error",
    "RETRY": "inprogress",
    "RETRY_REQ": "inprogress",
    "SERVR_ERR": "error",
    "TSP_REQUEST_FAILED": "error",
    "UPDATE_PARAMETERS": "info",
    "UPLOAD_DATA": "info",
    "WITHDRAW": "error",
    "WRITE_PARAMETERS_COMPLETED": "success",
    "WRITE_PARAMETERS_FAILED": "error",
    "WRITE_PARAMETERS_REQ": "inprogress",
    "SCHEDULE": "info",
    "IN_PROGRESS": "inprogress",
    "ELAPSED": "error",
    "CANCELLED": "error",
    "FILE_WRAPPING_FAILED": "error",
    "FILE_WRAPPING": "info",
    "SUCCESS": "success",
    "FAILED": "error",
    "FREEZE_FRAME_REQ": "inprogress",
    "PARAMS_READ_REQ": "inprogress",
    "WRITE_PARAMETERS_INIT": "info",
    "ERASE_DTC_REQ": "inprogress"

  },
  fileType: {
    "BASE": "Base",
    "CONFIGURATION": "Configuration",
    "BASE+CONFIGURATION": "Base and Configuration"
  },
  permissionModules: {
    "ADMIN": {
      schedule: ["view", "create", "edit", "delete", "withdraw", "approve", "triggerFlash", "forceCancel"],
      file: ["view", "create", "delete"],
      admin: ["view", "create", "userEdit", "userDelete", "userUpdate","vehicleDelete"],
      sync: ["view", "pdVehicleSync", "ecuMasterSync", "iAlert"]
    },
    "SCHEDULE_ADMIN": {
      schedule: ["view", "create", "edit", "delete", "withdraw", "approve", "triggerFlash", "forceCancel"],
      file: ["view", "create", "delete"],
      admin: [],
      sync: []
    },
    "SCHEDULE_CREATOR": {
      schedule: ["view", "create", "edit", "withdraw"],
      file: ["view", "create", "delete"],
      admin: [],
      sync: []
    },
    "SCHEDULE_EXECUTOR": {
      schedule: ["view", "triggerFlash"],
      file: [],
      admin: [],
      sync: []
    },
    "SCHEDULE_CREATOR_EXECUTOR": {
      schedule: ["view", "create", "edit", "delete", "withdraw", "triggerFlash", "forceCancel"],
      file: ["view"],
      admin: [],
      sync: []
    },
    "SCHEDULE_APPROVER_L1": {
      schedule: ["view", "approve"],
      file: ["view", "create", "delete"],
      admin: [],
      sync: []
    },
    "SCHEDULE_APPROVER_L2": {
      schedule: ["view", "approve"],
      file: ["view", "create", "delete"],
      admin: [],
      sync: [],
    },
    "SCHEDULE_RO": {
      schedule: ["view"],
      file: ["view"],
      admin: [],
      sync: []
    },
    "IT_ADMIN_RO": {
      schedule: ["view"],
      file: ["view"],
      admin: ["view"],
      sync: []
    },
    "ADMIN_RO": {
      schedule: ["view"],
      file: ["view"],
      admin: ["view"],
      sync: []
    }
  },
  errorCodes: {
    "ERR_GL011": "The Authorization token is not valid. Please try again with a valid token."
  },
  successCodes: {
    "approveSuccess": "Schedule Approved Successfully.",
    "rejectSuccess": "Schedule Rejected Successfully",
    "withdrawSuccess": "Schedule Withdrawn Successfully",
    "deleteSuccess":"Schedule Deleted Successfully",
    "resubmitSuccess": "Schedule Re-Submitted Successfully",
    "flashEcu": "Flash Triggered Successfully for Schedule type OTA Flash",
    "bulkTrigger": "A Flash Trigger command will be initiated for all the vehicles in the background if the condition matches",
    "updateParameters": "Flash Triggered Successfully for Schedule type Params Update",
    "deleteFile": "Flash Trigger Cancelled",
    "fileDelete": "File deleted Successfully",
    "createSchedule": "Schedule Created Successfully",
    "createScheduleWarning": "Schedule Created with following Warning",
    "editSchedule": "Schedule Edited Successfully",
    "addFile": "File Uploaded Successfully",
    "addUser": "User Added Successfully",
    "addVehicle": "Vehicle Added Successfully",
    "forceCancel": "Force Cancelled Scheduled Vehicle",
    "syncPDVehicleSuccess": "Manual Sync raised Successfully",
    "syncIAlertSuccess": "iAlert Sync raised Successfully",
    "xmlFileUploadSuccess": "XML File Uploaded Successfully",
    "changePasswordSuccess": "Password Changed Successfully",
    "deleteUserSuccess": "User Deleted Successfully",
    "deleteVehicleSuccess": "Vehicle Deleted Successfully",
    "editUserSuccess": "User Edited Successfully",
    "resetPasswordSuccess": "Password Reset Successfully",
    "retryTrigger": "Flash Triggred Successfully."
  },
  scheduleType: {
    "flashEcu": "ECU Flashing",
    "readDtc": "Read Error Codes",
    "clearDtc": "Read and Erase Error Codes",
    "readFreezeFrame": "Read Error Codes with Snapshot",
    "readParameters": "Read Parameter",
    "updateParameters": "Update Parameter"
  },
  userType: {
    "ADMIN": "Admin",
    "ADMIN_RO": "Admin Readonly",
    "IT_ADMIN_RO": "IT Admin Readonly",
    "SCHEDULE_ADMIN": "Schedule Admin",
    "SCHEDULE_RO": "Schedule Readonly",
    "SCHEDULE_APPROVER_L2": "Schedule Approver L2",
    "SCHEDULE_APPROVER_L1": "Schedule Approver L1",
    "SCHEDULE_CREATOR": "Schedule Creator",
    "SCHEDULE_EXECUTOR": "Schedule Executor",
    "SCHEDULE_CREATOR_EXECUTOR": "Schedule Creator Executor"
  },
  defaultScheduleFilter: {
    "ADMIN": "IN_PROGRESS",
    "ADMIN_RO": "IN_PROGRESS",
    "IT_ADMIN_RO": "IN_PROGRESS",
    "SCHEDULE_ADMIN": "IN_PROGRESS",
    "SCHEDULE_RO": "IN_PROGRESS",
    "SCHEDULE_APPROVER_L2": "APPROVAL_PENDING",
    "SCHEDULE_APPROVER_L1": "APPROVAL_PENDING",
    "SCHEDULE_CREATOR": "IN_PROGRESS",
    "SCHEDULE_EXECUTOR": "IN_PROGRESS",
    "SCHEDULE_CREATOR_EXECUTOR": "IN_PROGRESS"
  },
OBU_MAKE_TYPE:{
  'IT001':'iTriangle',
  'TAP76': 'Trimble',
  'DAN001': 'Danlaw'
  }
};

module.exports = config;

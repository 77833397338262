import React, { Component } from 'react';
import { Modal, Form } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import InlineAlert from '../../../../shared/notificationAlert/inlineAlert';
import {
  errorLogsApi,
  createUpdateParamScheduleSelectApi
} from "../../../../utill/api/schedulesApi";
import { scheduleType } from "../../../../utill/config";
import BulkMasterForm from './bulkMasterForm';
import UpdateParamManual from './updateParamManual';

const animatedComponents = makeAnimated();

class CreateProdUpdateParamScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createType: 'manual',
      error: null,
      disable: false,
      scheduleType: this.props.type,
      edit: this.props.editRow,
      alreadyScheduledVinData: [],
      alreadyScheduledVins: [],
    }
  }

  toggleCreateType = (type) => {
    const { createType } = this.state;
    if (type === createType) return;
    this.setState({ createType: type, error: null, disable: false });
  };

  createSchedule = (payload) => {
    const { scheduleType } = this.state;
    if (scheduleType === 'updateParameters') {
      this.createUpdateParamSchedule(payload);
    }
  };

  createUpdateParamSchedule = (payload) => {
    createUpdateParamScheduleSelectApi(payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.getAlreadyScheduledError(res);
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  };

  getAlreadyScheduledError = (res) => {
    const { createType } = this.state;
    if (res.errorCode === 'ERR_0119') {
      res.message = res.message.replace(/'/g, '"')
      const data = JSON.parse(res.message);
      const vinsTaken = data.map(item => item.vin);
      if (createType === 'manual') {
        res.message = `The VIN(s) ${vinsTaken.join()} are already a part of another schedule.`
        this.setState({ error: res, disable: false });
      } else {
        this.setState({
          alreadyScheduledVinData: data,
          alreadyScheduledVins: vinsTaken,
        });
      }
    } else {
      // In case of other Errors
      this.setState({ error: res, disable: false });
    }
  }

  getErrorLogs = (metadata) => {
    errorLogsApi(metadata.message/*scheduleId*/)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            if (res.length !== 0) {
              metadata.successCode = "createScheduleWarning";
              metadata.warning = true;
              metadata.logs = res;
            } else {
              metadata.successCode = "createSchedule";
              metadata.warning = false;
            }
            this.props.onHide();
            this.props.showModalSuccess(metadata);
            this.setState({ disable: false });
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  };

  render() {
    const { createType } = this.state;
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          backdrop={'static'}
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.state.edit ? "Edit Schedule" : `New ${scheduleType[this.state.scheduleType]} Schedule`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
            {['radio'].map((type) => (
              <div key={`inline-${type}`} className="form-group">
                <Form.Check
                  inline
                  label="Manual Select"
                  type={type} id={`inline-${type}-1`}
                  checked={createType==='manual'}
                  onClick={() => this.toggleCreateType('manual')}
                />
                <Form.Check
                  inline
                  label="Bulk Upload"
                  type={type} id={`inline-${type}-2`}
                  checked={createType==='bulk'}
                  onClick={() => this.toggleCreateType('bulk')}
                />
              </div>
            ))}
          </Form>
          <hr/>
          {/* Error Display */}
          {Boolean(this.state.error) ? (
              <InlineAlert error={this.state.error} />
            ) : null}
          {createType === 'manual' ?
            <UpdateParamManual
              type={this.props.type}
              getSchedule={() => this.props.getSchedule('0')}
              onHide={this.props.onHide}
              editRow={this.props.editRow}
              disable={this.state.disable}
              createSchedule={(payload) => this.createSchedule(payload)}
            />
            :
            <BulkMasterForm
              type={this.props.type}
              getSchedule={() => this.props.getSchedule('0')}
              onHide={this.props.onHide}
              createSchedule={(payload) => this.createSchedule(payload)}
              alreadyScheduledVinData={this.state.alreadyScheduledVinData}
              alreadyScheduledVins={this.state.alreadyScheduledVins}
            />
          }
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default CreateProdUpdateParamScheduleModal;
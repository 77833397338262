import React, { Component } from "react";
import { Modal, Button, Form, Col, Spinner } from "react-bootstrap";
import { userTypeApi, editUserApi } from "../../utill/api/userApi";
import { createUserApi } from "../../utill/api/userApi";
import InlineAlert from "../../shared/notificationAlert/inlineAlert";
import { ecuGroupApi } from "../../utill/api/ecuApi";

class UserAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      disable: false,
      edit: Boolean(this.props.editRow),
      editRow: this.props.editRow,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      userType: "",
      userTypeData: [],
      error: null,
      selectedUserType: ""
      /* ,
      ecuGroup: [],
      ecuGroupId: "",
      ecuGroupValue: "", */
    };
    this.getUserType = this.getUserType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    //  this.getEcuGroup = this.getEcuGroup.bind(this);
  }

  componentDidMount() {
    this.getUserType();
  }

  getUserType() {
    userTypeApi()
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (this.state.edit) {
              this.setState({ userTypeData: res, selectedUserType: this.state.editRow.userType });
            } else {
              this.setState({ userTypeData: res, selectedUserType: res[0].userTypeKey });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  // get ecu group
  getEcuGroup() {
    ecuGroupApi()
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            this.setState({
              ecuGroup: res,
              ecuGroupId: res[0].ecuGroupId,
              ecuGroupValue: res[0].ecuGroup,
            });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  changeUserType = (e) => {
    this.setState({ selectedUserType: e.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      this.setState({ disable: true });
      const {
        firstName,
        lastName,
        email,
        phoneNumber
      } = event.target;
      const payload = {
        firstName: firstName.value.trim(),
        lastName: lastName.value.trim(),
        emailId: email.value.trim(),
        phoneNumber: phoneNumber.value.trim(),
        userType: this.state.selectedUserType.trim()
        //     ecuGroupId: ecuGroupId.value
      };
      if (this.state.edit) {
        this.editUser(payload)
      } else {
        this.createUser(payload);
      }
    }
    this.setState({ validated: true });
  }

  createUser = (payload) => {
    createUserApi(payload)
      .then(res => {
        if (res.status === 200) {
          // res.json().then(res => {
          res.successCode = "addUser";
          this.props.getUsers('0');
          this.props.onHide();
          this.props.showModalSuccess(res);
          this.setState({ disable: false });
          // });
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          });
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
      });
  }

  editUser = (payload) => {
    editUserApi(this.state.editRow.userId, payload)
      .then(res => {
        if (res.status === 200) {
          res.successCode = "editUserSuccess";
          this.props.getUsers('0');
          this.props.onHide();
          this.props.showModalSuccess(res);
          this.setState({ disable: false });
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  }

  render() {
    const {
      edit,
      editRow,
      selectedUserType
    } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {edit ? "Edit User" : `New User`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Inline Error Alert */}
          {Boolean(this.state.error) ? (
            <InlineAlert error={this.state.error} />
          ) : null}
          {/* Form start */}
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSubmit}
          >
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  name="firstName"
                  required
                  type="text"
                  maxLength={45}
                  placeholder="First Name"
                  defaultValue={edit ? editRow.firstName : null}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter first Name.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  name="lastName"
                  required
                  type="text"
                  maxLength={45}
                  placeholder="Last Name"
                  defaultValue={edit ? editRow.lastName : null}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter last name.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  required
                  type="email"
                  maxLength={45}
                  placeholder="example@domain.com"
                  pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$"
                  defaultValue={edit ? editRow.emailId : null}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter email.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  name="phoneNumber"
                  required
                  type="text"
                  placeholder="Phone Number"
                  pattern="^((\\+91-?)|0)?[0-9]{10}$"
                  minLength={10}
                  maxLength={10}
                  defaultValue={edit ? editRow.phoneNumber : null}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter phone number.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                <Form.Label>User Type</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="userType"
                  value={selectedUserType}
                  onChange={this.changeUserType}
                >
                  <option value="" disabled defaultValue>
                    Select User Type
                  </option>
                  {this.state.userTypeData.map((data, i) => {
                    return (
                      <option
                        key={`userTypeKey_${i}`}
                        value={data.userTypeKey}
                      >
                        {data.displayName}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please choose User Types
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            {/*         <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                <Form.Label>ECU Group</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="ecuGroupId"
                // onChange={this.getEcuName}
                >
                  <option value="" disabled defaultValue>
                    Select ECU Group
                  </option>
                  {this.state.ecuGroup.map((data, i) => {
                    return (
                      <option
                        key={`ecuGroupKey_${i}`}
                        defaultValue={i === 0}
                        value={data.ecuGroupId}
                      >
                        {data.ecuGroup}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please choose User Types
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
 */}
            <Button type="submit" disabled={this.state.disable}>
              {this.state.disable ?
                <Spinner animation="border" variant="light" size="sm" role="status" />
                : null}
              {edit ? "Save" : "Add"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default UserAddModal;

import React, { Component } from "react";
import ToastAlert from "../../shared/notificationAlert/toastAlert";
import { getIAlertLog, syncIAlert } from "../../utill/api/syncApi";
import { Container, Row, Col, Button } from "react-bootstrap";
import CustomDataTable from "../../shared/customDataTable/customDataTable";
import ConfirmDeleteModal from "../../shared/modals/confirmDeleteModal";
import DateConverter from "../../shared/dateConverter/dateConverter";

const columns = [
  {
    name: "#",
    selector: "index",
    sortable: true,
    maxWidth: "50px"
  },
  {
    name: "Vehicle Added",
    selector: "vehiclesAdded",
    sortable: true,
    maxWidth: "150px",
  },
  // {
  //   name: "Vehicle Updated",
  //   selector: "vehicleUpdated",
  //   sortable: true,
  //   maxWidth: "150px",
  // },
  {
    name: "ECU Added",
    selector: "ecuGroupsAdded",
    sortable: true,
    minWidth: "150px",
  },
  {
    name: "File Added",
    selector: "filesAdded",
    sortable: true,
    minWidth: "150px",
  },
  // {
  //   name: "Error Count",
  //   selector: "errorCount",
  //   sortable: true,
  //   minWidth: "200px",
  // },
  {
    name: "Message",
    selector: "message",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Started At",
    selector: "startedAt",
    sortable: true,
    minWidth: "200px",
    cell: row => <DateConverter date={row.startedAt} />
  },
  {
    name: "Completed At",
    selector: "completedAt",
    sortable: true,
    minWidth: "200px",
    cell: row => <DateConverter date={row.completedAt} />
  },
  {
    name: "Triggered By",
    selector: "triggerBy",
    sortable: true,
    minWidth: "300px"
  }
];

class IAlert extends Component {
  currentPage = 0;
  constructor() {
    super();
    this.state = {
      iAlertLog: [],
      isLoading: true,
      error: null,
      showConfirmModal: false,
      disableConfirmButton: false
    };
    this.getIAlertLog = this.getIAlertLog.bind(this);

  }
  componentDidMount() {
    //this.getIAlertLog();
  }

  /**
* To get files
*/
  getIAlertLog(page) {
    getIAlertLog(page)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setIndexNumber(res, page);
            } else this.setState({ isLoading: false });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }
  /**
   * To set index for each row
   */
  setIndexNumber = (data, page) => {
    if (page == 0) {
      this.setState({ iAlertLog: [] });
    }
    data.map((row, index) => {
      return row.index = this.state.iAlertLog.length + index + 1;
    });
    if (page == 0) {
      this.setState({
        iAlertLog: data,
        isLoading: false
      })
    } else {
      this.setState(prevState => {
        return {
          iAlertLog: [...prevState.iAlertLog, ...data],
          isLoading: false
        }
      });
    }
    // this.setState({ iAlertLog: data, isLoading: false });
  };
  /**
  * 
  */
  loadNextPage = (page, currentPage) => {
    this.currentPage = currentPage;
    this.getIAlertLog(page);
  };

  syncIAlert = () => {
    this.setState({ disableConfirmButton: true });
    syncIAlert()
      .then(res => {
        if (res.status !== 200) {
          res.json().then(data => {
            this.setState({
              error: data,
              showConfirmModal: false,
              disableConfirmButton: false
            });
          });
        } else {
          res.successCode = "syncIAlertSuccess"
          this.setState({
            error: res,
            showConfirmModal: false,
            disableConfirmButton: false
          }, () => this.getIAlertLog(0));
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  };

  toggleConfirmModal = () => {
    this.setState(oldState => ({
      showConfirmModal: !oldState.showConfirmModal
    }));
  };

  errorHandled = () => {
    this.setState({ error: null });
  };

  render() {
    const {
      error,
      showConfirmModal,
      disableConfirmButton
    } = this.state;
    return (
      <>
        {/* Toast Alert */}
        {Boolean(error) ? (
          <ToastAlert
            show={Boolean(error)}
            onClose={this.errorHandled}
            error={error}
          />
        ) : null}
        {/* Page Content starts */}
        <Container fluid>
          <Row noGutters className="page-title">
            <Col className="col-6">
              <h1>iAlert Vehicle</h1>
            </Col>
            <Col className="col-6 text-right">
              <Button
                className="sync-button"
                variant="danger" size="md"
                onClick={this.toggleConfirmModal}
              >
                <i className={"fa fa-refresh " + this.state.syncing} aria-hidden="true"></i>
                <span className="name pl-2">Sync iAlert</span>
              </Button>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="data-table">
              <CustomDataTable
                tableColumns={columns}
                data={this.state.iAlertLog}
                loading={this.state.isLoading}
                loadNextPage={this.loadNextPage}
                currentPage={this.currentPage}
              />
            </Col>
          </Row>
        </Container>
        {/* Confirm Action Modal */}
        <ConfirmDeleteModal
          show={showConfirmModal}
          disable={disableConfirmButton}
          onHide={this.toggleConfirmModal}
          update={this.syncIAlert}
          action={"confirmIAlertSync"}
        />
      </>
    );
  }
}export default IAlert;
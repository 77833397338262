import React from "react";
import { Route, Redirect, BrowserRouter, withRouter, Switch } from "react-router-dom";
import Schedules from "../../components/Schedules/Schedules";
import Files from "../../components/Files/Files";
import Profile from "../../components/Profile/Profile";
import NotFound from "../../components/notFound/notFound";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Login, ScheduleDetail } from "../../components"
import Admin from "../../components/Admin/Admin";
import { permissionModules } from "../../utill/config";
import Sync from "../../components/Sync/Sync";
import DiagnosticsView from "../../components/DiagnosticsView/DiagnosticsView";
import { logoutApi } from "../api/loginApi";
import Dashboard from "../../components/Dashboard/Dashboard";

const Layout = ({ children, location }) => {
  if (!localStorage.token || !localStorage.currentUser) {
    localStorage.clear();
    return <Redirect to="/login" />;
  }
  return (
    <>
      <Header />
      <main className="main-content">{children}</main>
      <Footer />
    </>
  );
};
let currentUser = {};
const user = localStorage.getItem("currentUser");
if (user) { currentUser = JSON.parse(user); }
class Router extends React.Component {

  // state = {
  //   filePermission: false,
  //   adminPermission: false
  // };

  // componentDidMount() {
  //   if (currentUser && currentUser.userType && permissionModules[currentUser.userType].file) {
  //     this.setState({ filePermission: true });
  //   }
  //   if (currentUser && currentUser.userType && permissionModules[currentUser.userType].admin) {
  //     this.setState({ adminPermission: true });
  //   }
  // }

  logout = () => {
    const encodedToken = localStorage.getItem("encodedToken");
    // logoutApi(encodedToken)
    localStorage.clear();
    return <Redirect to="/login" />
  };

  checkFilePermission = (props) => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && currentUser.userType && permissionModules[currentUser.userType].file.includes("view")) {
      return <Layout><Files {...props} /></Layout>
    } else {
      return <Layout><NotFound /></Layout>
    }
  };

  checkAdminPermission = (props) => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && currentUser.userType && permissionModules[currentUser.userType].admin.includes("view")) {
      return <Layout><Admin {...props} /></Layout>
    } else {
      return <Layout><NotFound /></Layout>
    }
  };

  checkSyncPermission = (props) => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && currentUser.userType && permissionModules[currentUser.userType].sync.includes("view")) {
      return <Layout><Sync {...props} /></Layout>
    } else {
      return <Layout><NotFound /></Layout>
    }
  };


  render() {
    return (
      // <BrowserRouter>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path={"/dashboard"} exact render={(props) => <Layout><Dashboard {...props} /></Layout>} />
          <Route path={["/", "/schedules"]} exact render={(props) => <Layout><Schedules {...props} /></Layout>} />
          <Route path="/schedules/:scheduleId/:type" exact render={(props) => <Layout><ScheduleDetail {...props} /></Layout>} />
          <Route path="/files" exact render={(props) => this.checkFilePermission(props)} />
          <Route path={["/admin", "/admin/users", "/admin/vehicles"]} exact render={(props) => this.checkAdminPermission(props)} />
          <Route path={["/sync","/sync/*"]} exact render={(props) => this.checkSyncPermission(props)} />
          <Route path="/profile" exact render={(props) => <Layout><Profile {...props} /></Layout>} />
          <Route path="/diagnostics/:scheduleType/:scheduleId/:vehicleId" exact render={(props) => <Layout><DiagnosticsView {...props} /></Layout>} />
          <Route path="/logout" exact render={this.logout} />
          <Route render={() => <Layout><NotFound /></Layout>} />
        </Switch>
      // </BrowserRouter>
    );
  }
}

export default withRouter(Router);

import React, { useState, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { permissionModules } from "../../utill/config";

const ScheduleTrigger = props => {
  const { row, scheduleDetail } = props;
  const [vehicle, setVehicle] = useState([]);
  const [schedule, setShedule] = useState([]);
  useEffect(() => { setVehicle(row)}, [row] )
  useEffect(() => { setShedule(scheduleDetail)}, [scheduleDetail] )
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let isPermitted = true;

  const updateStatus = (row, scope) => {
    props.confirmDelete({ row, scope });
  };

  if (
    currentUser &&
    currentUser.userType &&
    currentUser.userType === "SCHEDULE_CREATOR_EXECUTOR" &&
    (permissionModules[currentUser.userType].schedule.includes("triggerFlash") ||
      permissionModules[currentUser.userType].schedule.includes("forceCancel"))
  ) {
    isPermitted =
      currentUser &&
        currentUser.userId &&
        currentUser.userId === schedule.createdBy
        ? true
        : false;
  }

  return (
    <span>
      {/* Flash Ecu Trigger Button and conditions */}
      {currentUser &&
      currentUser.userType &&
      isPermitted &&
      permissionModules[currentUser.userType].schedule.includes("triggerFlash") &&
      vehicle.status === "FILE_DWLD_COMPLETED"  &&
      schedule.type === "flashEcu" ? (
        <>
          {vehicle.triggerEligible ?
          <Button
            className="mr-2 action-btn"
            variant="primary"
            size="sm"
            onClick={() => updateStatus(vehicle, schedule.type)}
            >
            <i className="fa fa-bolt action-button-icon" aria-hidden="true"></i>&nbsp;
            Trigger
          </Button>
          :
          <OverlayTrigger placement="left" overlay={
            <Tooltip id="tooltip-disabled">
              Trigger Flash criteria not met. For Trigger eligibility: <br/>
              1. Ignition must be: ON <br/>
              2. Engine must be: OFF <br/>
              3. Vehicle must be: NOT RUNNING<br/>
              4. Last communication time {'>'}( Current Time (Server time) - 5mins).
            </Tooltip>
            }>
            <span className="d-inline-block">
              <Button
                className="mr-2 action-btn"
                variant="primary"
                size="sm"
                disabled
                style={{ pointerEvents: 'none' }}
                onClick={() => updateStatus(vehicle, schedule.type)}
                >
                <i className="fa fa-bolt action-button-icon" aria-hidden="true"></i>&nbsp;
                Trigger
              </Button>
            </span>
          </OverlayTrigger>}
          {/* <Button
            className="mr-2 action-btn"
            variant="danger"
            size="sm"
            onClick={() => updateStatus(vehicle, "deleteFile")}
            >
            <i className="fa fa-ban action-button-icon" aria-hidden="true"></i>&nbsp;
            Cancel
          </Button> */}
        </>
      ) : null}

      {/* Update Parameters Trigger button */}
      {currentUser &&
      currentUser.userType &&
      isPermitted &&
      permissionModules[currentUser.userType].schedule.includes("triggerFlash") &&
      vehicle.status === "FILE_DWLD_COMPLETED" &&
      schedule.type === "updateParameters" ? (
        <>
          <Button
            className="mr-2 action-btn"
            variant="primary"
            size="sm"
            onClick={() => updateStatus(vehicle, schedule.type)}
            >
            <i className="fa fa-bolt action-button-icon" aria-hidden="true"></i>&nbsp;
            Trigger
          </Button>
          {/* <Button
            className="mr-2 action-btn"
            variant="danger"
            size="sm"
            onClick={() => updateStatus(vehicle, "deleteFile")}
            >
            <i className="fa fa-ban action-button-icon" aria-hidden="true"></i>&nbsp;
            Cancel
          </Button> */}
        </>
      ): null}

      {/* Force Cancel Button */}
      {currentUser &&
      currentUser.userType &&
      isPermitted &&
      permissionModules[currentUser.userType].schedule.includes("forceCancel") &&
      vehicle.status !== "APPROVAL_PENDING" &&
      !vehicle.processed ? (
        <Button
          className="btn action-btn mr-2"
          size="sm"
          variant="warning"
          onClick={() => updateStatus(vehicle, "forceCancel")}
        >
          <i className="fa fa-ban action-btn-icon">&nbsp;</i>
          Cancel
        </Button>
      ) : null}
    </span>
  );
};

export default ScheduleTrigger;

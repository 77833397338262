import React, { Component } from 'react';
import { Row, Col, Container, Dropdown, Button, Table } from 'react-bootstrap';
import "./Dashboard.scss";
import CalendarOverlay from '../../shared/calendarOverlay/CalendarOverlay';
import {
  getOverallCountApi,
  getCountByTypeApi,
  downloadReportApi,
  getLiveDataApi,
  getSyncDataApi
} from '../../utill/api/dashboardApi'
import ToastAlert from '../../shared/notificationAlert/toastAlert';
import { scheduleType } from "../../utill/config";
import DateConverter from '../../shared/dateConverter/dateConverter';
import Moment from 'react-moment';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      date: [(Date.parse(new Date().toUTCString())), (Date.parse(new Date().toUTCString()))],
      today: new Date(),
      isLoading: false,
      allScheduleCount: {},
      allVehicleCount: {},
      scheduleCountByType: {},
      vehicleCountByType: {},
      liveData: {},
      syncData: {},
      selectedType: 'all'
    }
  }

  componentDidMount() {
    // this.getOverallSchedules();
    // this.getAllScheduleTypes();
    // this.getScheduleByType();
  }

  onDateChange = (date) => {
    const from = Date.parse(date[0].toUTCString());
    const to = Date.parse(date[1].toUTCString());
    const milliDate = [from, to]
    this.setState({ date: milliDate },
      () => this.refreshValues());
  }

  refreshValues = () => {
    this.getOverallCount(this.state.date[0], this.state.date[1]);
    this.getLiveData(this.state.date[0], this.state.date[1]);
    this.getSyncData();
    // this.getCountByType(this.state.date[0], this.state.date[1]);
  }

  getOverallCount = (from, to) => {
    getOverallCountApi(from, to)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            this.setState({
              allScheduleCount: res,
              allVehicleCount: res.vehicles,
              isLoading: false
            }, () => this.onTypeChange(this.state.selectedType));
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }

  getCountByType = (from, to, type) => {
    getCountByTypeApi(from, to, type)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            this.setState({
              scheduleCountByType: res,
              vehicleCountByType: res.vehicles,
              isLoading: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }

  onTypeChange = (type) => {
    if (type === 'all') {
      const { allScheduleCount, allVehicleCount } = this.state;
      this.setState({
        selectedType: type,
        scheduleCountByType: allScheduleCount,
        vehicleCountByType: allVehicleCount
      });
    } else {
      this.setState({ selectedType: type });
      this.getCountByType(this.state.date[0], this.state.date[1], type)
    }
  }

  downloadReport = (time) => {
    let from;
    let to;
    const t = new Date();
    if (time === "last15days") {
      from = Date.parse(new Date(t.setDate(t.getDate() - 15)));
      to = Date.parse(new Date());
    } else if (time === "last30days") {
      from = Date.parse(new Date(t.setDate(t.getDate() - 30)));
      to = Date.parse(new Date());
    } else if (time === "last60days") {
      from = Date.parse(new Date(t.setDate(t.getDate() - 60)));
      to = Date.parse(new Date());
    } else if (time === "last90days") {
      from = Date.parse(new Date(t.setDate(t.getDate() - 90)));
      to = Date.parse(new Date());
    } else if (time === "last180days") {
      from = Date.parse(new Date(t.setDate(t.getDate() - 180)));
      to = Date.parse(new Date());
    } else if (time === "last365days") {
      from = Date.parse(new Date(t.setDate(t.getDate() - 365)));
      to = Date.parse(new Date());
    } else {
      let calSelectedDate = this.state.date;
      from = calSelectedDate[0];
      to = calSelectedDate[1];
    }
    downloadReportApi(from, to)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            var start = new Date(from);
            var end = new Date(to);
            a.download = `Report_From_${start.toLocaleDateString()}_to_${end.toLocaleDateString()}.csv`;
            a.click();
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }

  getLiveData = (from,to) => {

    getLiveDataApi(from,to)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            this.setState({
              liveData: res,
              isLoading: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }

  getSyncData = () => {
    getSyncDataApi()
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            this.setState({
              syncData: res,
              isLoading: false
            });
          });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }

  errorHandled = () => {
    this.setState({ error: null });
  };

  render() {
    const {
      error,
      date,
      today,
      allScheduleCount,
      allVehicleCount,
      scheduleCountByType,
      vehicleCountByType,
      selectedType,
      liveData,
      syncData
    } = this.state;
    return (
      <>
        {/* Toast Alert */}
        {Boolean(error) ? (
          <ToastAlert
            show={Boolean(error)}
            onClose={this.errorHandled}
            error={error}
          />
        ) : null}
        <Container fluid className="outer-container">
          <Row noGutters className="page-title">
            <Col md="3" className="py-4">
              <h3>Dashboard</h3>
            </Col>
           

           
            <Col md="9" className="py-4 text-right">
            <Row>
              <Col md="10" className='text-right'>
              <CalendarOverlay onChange={this.onDateChange} />
              </Col>
              <Col md="2">
              {/* Download dropdown */}
              <Dropdown >
                <Dropdown.Toggle
                  variant={"primary"}
                  size={"sm"}
                  id="dropdown-basic" disabled={this.state.isLoading}>
                  <i className="fa fa-cloud-download" aria-hidden="true"></i>
                  &nbsp;Download Report
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.downloadReport("last15days")}>
                  <i className="text-muted fa fa-download" aria-hidden="true"></i>  Last 15 days
                  </Dropdown.Item>
                  <div class="dropdown-divider"></div>
                  <Dropdown.Item onClick={() => this.downloadReport("last30days")}>
                  <i className="text-muted fa fa-download" aria-hidden="true"></i> Last 30 days
                  </Dropdown.Item>
                  <div class="dropdown-divider"></div>
                  <Dropdown.Item onClick={() => this.downloadReport("last60days")}>
                  <i className="text-muted fa fa-download" aria-hidden="true"></i> Last 60 days
                  </Dropdown.Item>
                  <div class="dropdown-divider"></div>
                  <Dropdown.Item onClick={() => this.downloadReport("last90days")}>
                  <i className="text-muted fa fa-download" aria-hidden="true"></i> Last 90 days
                  </Dropdown.Item>
                  <div class="dropdown-divider"></div>
                  <Dropdown.Item onClick={() => this.downloadReport("last180days")}>
                  <i className="text-muted fa fa-download" aria-hidden="true"></i> Last 180 days
                  </Dropdown.Item>
                  <div class="dropdown-divider"></div>
                  <Dropdown.Item onClick={() => this.downloadReport("last365days")}>
                  <i className="text-muted fa fa-download" aria-hidden="true"></i>  Last 365 days
                  </Dropdown.Item>
                  <div class="dropdown-divider"></div>
                  <Dropdown.Item onClick={() => this.downloadReport("complete")}>
                  <i class="text-muted fa fa-calendar" aria-hidden="true"></i> Selected Range
                  
                  <>
                  <p className='small text-muted mt-2'>
                    <Moment date={date[0]} format="DD-MMM-YYYY"></Moment> to <Moment date={date[1]} format="DD-MMM-YYYY"></Moment></p>
                  </>
                  
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </Col>
              </Row>
              {/* <Button size="sm" className="ml-2" onClick={this.downloadReport}>
                <i className="fa fa-cloud-download" aria-hidden="true"></i>
                &nbsp;Download Report
              </Button> */}
            </Col>
          </Row>
          <Row noGutters className="dashboard-body">
            <Col md="8" className="pr-4">
              <div className="primaryContainer summary">
                <h5>Schedule Summary</h5>
                <div className="count-wrapper">
                  <div className="count-container">
                    <div className="count">{allScheduleCount.total || 0}</div>
                    <div className="text">Total Schedules</div>
                  </div>
                  <div className="count-container">
                    <div className="count">{allVehicleCount.total || 0}</div>
                    <div className="text">Total Vehicles</div>
                  </div>
                </div>
              </div>
              <div className="primaryContainer details">
                <div className="text-right filterBtn pt-3 pr-3">
                  <Dropdown className="mr-2" style={{ display: "inline-block" }}>
                    <Dropdown.Toggle
                      size="sm" id="dropdown-basic"
                      variant={scheduleType[selectedType] ? "primary" : "outline-primary"}
                    >
                      <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;
                      Filter by {scheduleType[selectedType] ? scheduleType[selectedType] : "All Schedules"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        active={selectedType === "all"}
                        onClick={() => this.onTypeChange("all")}>
                        All Schedules&emsp;
                        {selectedType === "all" ?
                          <i className="fa fa-check" aria-hidden="true"></i>
                          : null}
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={selectedType === "flashEcu"}
                        onClick={() => this.onTypeChange("flashEcu")}>
                        ECU Flashing&emsp;
                        {selectedType === "flashEcu" ?
                          <i className="fa fa-check" aria-hidden="true"></i>
                          : null}
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={selectedType === "readDtc"}
                        onClick={() => this.onTypeChange("readDtc")}>
                        Read Error Codes&emsp;
                        {selectedType === "readDtc" ?
                          <i className="fa fa-check" aria-hidden="true"></i>
                          : null}
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={selectedType === "clearDtc"}
                        onClick={() => this.onTypeChange("clearDtc")}>
                        Read and Erase Error Codes&emsp;
                        {selectedType === "clearDtc" ?
                          <i className="fa fa-check" aria-hidden="true"></i>
                          : null}
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={selectedType === "readFreezeFrame"}
                        onClick={() => this.onTypeChange("readFreezeFrame")}>
                        Read Error Codes with Snapshot&emsp;
                        {selectedType === "readFreezeFrame" ?
                          <i className="fa fa-check" aria-hidden="true"></i>
                          : null}
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={selectedType === "readParameters"}
                        onClick={() => this.onTypeChange("readParameters")}>
                        Read Parameter&emsp;
                        {selectedType === "readParameters" ?
                          <i className="fa fa-check" aria-hidden="true"></i>
                          : null}
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={selectedType === "updateParameters"}
                        onClick={() => this.onTypeChange("updateParameters")}>
                        Update Parameter&emsp;
                        {selectedType === "updateParameters" ?
                          <i className="fa fa-check" aria-hidden="true"></i>
                          : null}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* Schedule Based */}
                <h6 className="heading fancy"><span>Schedule Details</span></h6>
                <div className="count-wrapper">
                  <div className="count-container">
                    <div className="count">{scheduleCountByType.total || 0}</div>
                    <div className="text">Schedules</div>
                  </div>
                  <div className="count-container">
                    <div className="count">{scheduleCountByType.inProgress || 0}</div>
                    <div className="text">In Progress</div>
                  </div>
                  <div className="count-container">
                    <div className="count">{scheduleCountByType.completed || 0}</div>
                    <div className="text">Completed</div>
                  </div>
                  <div className="count-container">
                    <div className="count">{scheduleCountByType.pendingForApproval || 0}</div>
                    <div className="text">Pending Approval</div>
                  </div>
                  <div className="count-container">
                    <div className="count">{scheduleCountByType.rejected || 0}</div>
                    <div className="text">Rejected</div>
                  </div>
                </div>
                {/* Vehicle Based */}
                <h6 className="heading fancy"><span>Vehicle Details</span></h6>
                <div className="count-wrapper">
                  <div className="count-container">
                    <div className="count">{vehicleCountByType.total || 0}</div>
                    <div className="text">Vehicles</div>
                  </div>
                  <div className="count-container">
                    <div className="count">{vehicleCountByType.inProgress || 0}</div>
                    <div className="text">In Progress</div>
                  </div>
                  <div className="count-container">
                    <div className="count">{vehicleCountByType.success || 0}</div>
                    <div className="text">Success</div>
                  </div>
                  <div className="count-container">
                    <div className="count">{vehicleCountByType.failed || 0}</div>
                    <div className="text">Failure</div>
                  </div>
                  <div className="count-container">
                    <div className="count">{vehicleCountByType.expired || 0}</div>
                    <div className="text">Expired</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="primaryContainer handler">
                <h5>Live Handler</h5>
                <div className="count-wrapper">
                  <div className="count-container">
                    <div className="count text-success">
                      {new Date(date[1]).getDate() === today.getDate() &&
                        new Date(date[1]).getMonth() === today.getMonth() &&
                        new Date(date[1]).getFullYear() === today.getFullYear() ?
                        liveData.liveSessions
                      : "NA"}
                    </div>
                    <div className="text text-success">Live Sessions</div>
                  </div>
                  <div className="count-container mt-3">
                    <div className="count">{liveData.totalRemoteSessions || 0}</div>
                    <div className="text">Total Remote Sessions</div>
                  </div>
                  <div className="count-container mt-3">
                    <div className="count">{liveData.totalUniqueRemoteSessions || 0}</div>
                    <div className="text">Total Unique Vehicles</div>
                  </div>
                </div>
              </div>
              <div className="primaryContainer sync">
                <h5>Sync Updates</h5>
                <div className="px-3">
                  <Table borderless size="sm">
                    <thead>
                      <tr>
                        <th>Source</th>
                        <th>Last Accessed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>iAlert Master Table</td>
                        <td><DateConverter date={syncData.iAlertMasterTable} /></td>
                      </tr>
                      <tr>
                        <td>ConnectALL master XML</td>
                        <td><DateConverter date={syncData.connectAllMasterXml} /></td>
                      </tr>
                      {/* <tr>
                        <td>HEX File Location</td>
                        <td>Tue, Apr 3, 4:37 AM IST</td>
                      </tr> */}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* Fota stats */}
        {/* <Row className="outer-container">
            <Col md="12 text-center">
              <h5 className="main-title my-3">FOTA STATS</h5>
            </Col>
            <Col md="12 mb-4">
            <h6 className="heading fancy"><span>Schedule Based</span></h6>
              <div class="table100 ver1 m-b-110">
                <div class="table100-head">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th class="cell100 column1">
                          Total Schedules Created <br/>
                          <small>(Withdrawn Schedules to be excluded)</small>
                        </th>
                        <th class="cell100 column2">In-Progress</th>
                        <th class="cell100 column3">Completed</th>
                        <th class="cell100 column4">Pending for Approval</th>
                        <th class="cell100 column5">Rejected</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="table100-body js-pscroll ps ps--active-y">
                  <table>
                    <tbody>
                      <tr class="row100 body">
                        <td class="cell100 column1">200</td>
                        <td class="cell100 column2">150</td>
                        <td class="cell100 column3">35</td>
                        <td class="cell100 column4">0</td>
                        <td class="cell100 column5">10</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
            <Col md="12 mb-4">
              <h6 className="heading fancy"><span>Vehicle Based</span></h6>
              <div className="unique-count-container">
                <div className="text">
                  <span>Total No. of Unique Vehicles: </span>
                </div>
                <span className="count">150</span>
              </div>
              <div class="table100 ver1 m-b-110">
                <div class="table100-head">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th class="cell100 column1">Total No. of Vehicles</th>
                        <th class="cell100 column2">In-Progress</th>
                        <th class="cell100 column3">Success</th>
                        <th class="cell100 column4">Failure</th>
                        <th class="cell100 column5">Schedule Expired</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="table100-body js-pscroll ps ps--active-y">
                  <table>
                    <tbody>
                      <tr class="row100 body">
                        <td class="cell100 column1">300</td>
                        <td class="cell100 column2">200</td>
                        <td class="cell100 column3">50</td>
                        <td class="cell100 column4">40</td>
                        <td class="cell100 column5">10</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row> */}
        {/* Remote Diagnostics Stats */}
        {/* <Row className="outer-container mt-3">
            <Col md="12 text-center">
              <h5 className="main-title my-3">REMOTE SESSION STATS</h5>
            </Col>
            <Col md="12 mb-4 text-center">
              <div class="table100 table70">
                <div class="table100-head">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th class="cell100 column1">Total No. of Sessions</th>
                        <th class="cell100 column2">No. of Unique Vehicles</th>
                        <th class="cell100 column3">Current Live Sessions</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="table100-body">
                  <table>
                    <tbody>
                      <tr class="row100 body">
                        <td class="cell100 column1">300</td>
                        <td class="cell100 column2">200</td>
                        <td class="cell100 column3">50</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row> */}
      </>
    );
  }
}

export default Dashboard;
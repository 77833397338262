import React, { Component } from "react";
import "./search.scss";
import {
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown
} from "react-bootstrap";

/**
 * Provide following data:
 * dropdown: boolean (optional)
 * list: [{displayText: "", key: ""}] (optional but required if dropdown)
 * data: [{}]
 * search: (searchedData) => {}
 */
class Search extends Component {
  title = {};
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.list,
      // title: {},
      typeValue: ""
    };
    if (this.props.dropdown) {
      // this.setState({title: this.state.items[0]});
      this.title = this.state.items[0];
    } else {
      // this.setState({ title: {key: "vin"} });
      this.title = {key: "vin"};
    }
  }

  onTypeSelect = index => {
    const title = {
      displayText: this.state.items[index].displayText,
      key: this.state.items[index].key
    };
    // console.log(title)
    // this.setState({ title: title });
    this.title = title;
    this.onSearch(this.state.typeValue);
  };

  onSearch = typeValue => {
    typeValue = typeValue.toLowerCase();
    // eslint-disable-next-line array-callback-return
    const filteredData = this.props.data.filter(r => {
      if (r[this.title.key] !== undefined && r[this.title.key] !== null)
      return r[this.title.key].toLowerCase().indexOf(typeValue) !== -1 || !typeValue;
    });
    filteredData.map((row, index) => {
      return row.index = index + 1;
    });
    this.props.search(filteredData);
  };

  render() {
    return (
      <>
        <InputGroup className="search-box">
          {this.props.dropdown ? (
            <DropdownButton
              as={InputGroup.Prepend}
              variant="outline-primary"
              title={this.title.displayText ? this.title.displayText:"Select Type"}
              id="input-group-dropdown"
              onSelect={e => this.onTypeSelect(e)}
            >
              {this.state.items.map((item, index) => (
                <Dropdown.Item eventKey={index} key={index}>
                  {item.displayText}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          ) : null}
          <FormControl
            placeholder="Search"
            aria-label="Search any keyword"
            onChange={e => {this.onSearch(e.target.value); this.setState({ typeValue: e.target.value })}}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <i className="fa fa-search"></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </>
    );
  }
}

export default Search;

import React from 'react';
import './App.css';
import './index.scss';
import Router from './app/utill/router/router'

function App(props) {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;

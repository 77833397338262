import React, { Component } from 'react';
import { Modal, Form, Col, Button, Spinner } from 'react-bootstrap';
import InlineAlert from '../../shared/notificationAlert/inlineAlert';
import { changePasswordApi } from '../../utill/api/loginApi';

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      validated: false,
      disable: false,
      noMatch: null
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      oldPassword,
      newPassword,
      confirmPassword
    } = event.target;
    if (newPassword.value !== confirmPassword.value) {
      this.setState({ noMatch: true });
    } else if (form.checkValidity()) {
      this.setState({ disable: true, noMatch: false });
      // const currentUser = JSON.parse(localStorage.getItem("currentUser"));
      const payload = {
        oldPassword: oldPassword.value,
        newPassword: newPassword.value,
        confirmPassword: confirmPassword.value
      };
      this.changePassword(payload);
    }
    this.setState({ validated: true });
  }

  changePassword = (payload) => {
    changePasswordApi(payload)
      .then(res => {
        if (res.status === 200) {
          res.successCode = "changePasswordSuccess";
          this.props.onHide();
          this.props.showModalSuccess(res);
          this.setState({ disable: false, isConfirmed: false, validated: false });
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  }

  render() {
    const {
      validated,
      disable,
      isConfirmed,
      noMatch
    } = this.state;
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          backdrop={'static'}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Boolean(this.state.error) ? (
              <InlineAlert error={this.state.error} />
            ) : null}
            <Form
              noValidate
              encType="multipart/form-data"
              validated={validated}
              onSubmit={this.handleSubmit}
            >
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="oldPassword">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control
                    required
                    name="oldPassword"
                    type="password"
                    autoComplete="off"
                    placeholder="Enter Old Password"
                    maxLength={45}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the Old Password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="newPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    required
                    name="newPassword"
                    type="password"
                    autoComplete="off"
                    placeholder="Enter New Password"
                    maxLength={45}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter New Password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="confirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    required
                    name="confirmPassword"
                    type="password"
                    autoComplete="off"
                    placeholder="Re-enter New Password"
                    maxLength={45}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Re-enter the New Password.
                  </Form.Control.Feedback>
                  {noMatch !== null && noMatch ? (
                    <>
                      <small className="text-danger">
                        Passwords did not match.
                      </small>
                    </>
                  ) : null}
                </Form.Group>
              </Form.Row>
              <div className="text-right mt-3">
                <Button className="mr-2" variant="primary" onClick={this.props.onHide} disabled={disable}>
                  <i className="fa fa-times" aria-hidden="true">&nbsp;</i>
                  Close
                </Button>
                <Button type="submit" variant="danger" disabled={disable}>
                  {disable ?
                    <Spinner animation="border" variant="light" size="sm" role="status" />
                    : <i className="fa fa-key" aria-hidden="true">&nbsp;</i>
                  }
                  Change
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ChangePasswordModal;
import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

function ConfirmDeleteModal(props) {
  const action = props.action;
  let title = "Alert!"
  let message = "";

  if (action === "flashEcu") {
    title = "Trigger Flash";
    message = "This will trigger a `Flash` command to vehicle";
  } else if (action === "retryTrigger") {
    title = "Retry";
    message = "This will trigger a `Retry` command to vehicle";
  } else if (action === "bulkTrigger") {
    title = "Bulk Trigger Flash";
    message = "This will trigger a `Flash` command to all the vehicles based on the matching criteria.";
  } else if (action === "updateParameters") {
    title = "Update Parameter";
    message = "This will trigger a `Update Parameter` command to vehicle";
  } else if (action === "deleteFile") {
    title = "Cancel Flash";
    message = "This will trigger a `Cancel` command to vehicle";
  } else if (action === "APPROVED") {
    title = "Approve Schedule";
    message = "The schedule will be active and vehicles will get the commands";
  } else if (action === "REJECTED") {
    title = "Reject Schedule";
    message = "This will make schedule rejected.";
  } else if (action === "WITHDRAW") {
    title = "Withdraw Schedule";
    message = "This will make schedule withdran. You will be able to update the schedule.";
  } else if (action === "delete") {
    title = "Delete File";
    message = "This will delete the file.";
  } else if (action === "forceCancel") {
    title = "Cancel Flash";
    message = "This will trigger a `Cancel` command to vehicle.";
  } else if (action === "DELETE_SCHEDULE") {
    title = "Delete Schedule";
    message = "This will permanently delete the schedule";
  } else if (action === "confirmSync") {
    title = "Sync PD Vehicle";
    message = "This will initiate a iAlert Sysnc. If any vehicles are in progress, may thow an error.";
  } else if (action === "confirmIAlertSync") {
    title = "Sync iAlert Vehicle";
    message = "This will initiate a iAlert Sysnc. If any vehicles are in progress, may thow an error.";
  }  else if (action === "userDelete") {
    title = "Delete User";
    message = "This will delete this user from system. He will not be able to access system.";
  } else if (action === "vehicleDelete") {
    title = "Delete Vehicle";
    message = "This will delete the vehicle.";
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop={'static'}
        keyboard={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message} <br /><br />
      
          <span className="text-danger "> This action cannot be undone. Are you sure to proceed?</span>
        </Modal.Body>
        <Modal.Footer
          >
          <Button variant="outline-secondary" onClick={props.onHide} disabled={props.disable}>
            <i className="fa fa-times" aria-hidden="true">&nbsp;</i>
            No
          </Button>
          <Button variant="danger" onClick={props.update} disabled={props.disable}>
            {props.disable ?
              <Spinner animation="border" variant="light" size="sm" role="status" />
              : <i className="fa fa-check" aria-hidden="true">&nbsp;</i>
            }
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmDeleteModal;

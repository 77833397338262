import React from "react";
import { Button } from "react-bootstrap";
import { permissionModules } from "../../utill/config";

const DeleteFile = props => {
  const deleteFile = () => {
    props.confirmDelete(props.row);
  };
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  return (
    <>
      {currentUser &&
      currentUser.userType &&
      permissionModules[currentUser.userType].file.includes("delete") ? (
        <Button
          className="mr-2 action-btn"
          variant="outline-danger"
          size="sm"
          onClick={() => deleteFile()}
        >
          <i className="fa fa-trash-o action-button-icon">&nbsp;</i>
          Delete
        </Button>
      ) : null}
    </>
  );
};

export default DeleteFile;

import React, { Component } from 'react';
import { Modal, Form, Spinner, Button, InputGroup, Col, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import InlineAlert from '../../../../shared/notificationAlert/inlineAlert';
import AsyncSelect from "react-select/async";
import makeAnimated from 'react-select/animated';
import { vehiclesApi, ecuNameApi, ecuGroupApi } from '../../../../utill/api/ecuApi';
import {
  errorLogsApi,
  editScheduleApi,
  createUpdateParamScheduleSelectApi,
  createUpdateParamScheduleUploadApi,
  getParametersApi
} from '../../../../utill/api/schedulesApi';
import Select from 'react-select';
import { vehicleSearchApi } from '../../../../utill/api/vehicleApi';

const animatedComponents = makeAnimated();

class CreateUpdateParamSchedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: Boolean(this.props.editRow),
      editRow: this.props.editRow,
      type: this.props.type,
      validated: false,
      disable: false,
      error: null,
      ecuGroupList: [],
      selectedGroup: '',
      selectedGroupId: '',
      selectedGroupObj: {},
      ecuNameList: [],
      selectedName: '',
      selectedEcuOtaFlsRefName: '',
      selectedNameObj: {},
      selectedParameter: {},
      isParameterSelected: null,
      checkboxValue: "select",
      selectedVehicles: [],
      isVehicleSelected: null,
      invalidFileName: false,
      scheduleExpiryDays: 14
    }

    this.getVehicles = this.getVehicles.bind(this);
    this.getParameters = this.getParameters.bind(this);
    this.getEcuGroup = this.getEcuGroup.bind(this);
    this.getEcuName = this.getEcuName.bind(this);
    this.onEcuNameChange = this.onEcuNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // this.getVehicles();
    // if (this.state.edit) {
    //   this.getSelectedVehicles();
    // }
    this.getEcuGroup();
  }

  getEcuGroup() {
    ecuGroupApi()
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (this.state.edit) {
              let ecuGroupObj = res.filter(item => {
                return item.ecuGroup === this.state.editRow.ecuGroup;
              });
              this.setState({
                ecuGroupList: res,
                selectedGroup: this.state.editRow.ecuGroup,
                selectedGroupObj: ecuGroupObj
              }, () => this.getEcuName());
            } else {
              if (res.length) {
                this.setState({
                  ecuGroupList: res,
                  selectedGroup: res[0].ecuGroup,
                  selectedGroupObj: res[0]
                }, () => this.getEcuName());
              }
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  onEcuGroupChange = (e) => {
    const ecuGroup = e.target.value;
    let ecuGroupObj;
    this.state.ecuGroupList.map(item => {
      if (item.ecuGroup == ecuGroup) ecuGroupObj = item;
    });
    this.setState({
      selectedGroup: ecuGroupObj.ecuGroup,
      selectedGroupObj: ecuGroupObj,
      selectedParameter: {}
    }, () => this.getEcuName());
  }

  getEcuName() {
    ecuNameApi(this.state.selectedGroup)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (this.state.edit && !this.state.groupChanged) {
              this.setState({
                ecuNameList: res,
                selectedName: this.state.editRow.ecuName,
                selectedEcuOtaFlsRefName: this.state.editRow.ecuOtaFlsRef
              }, () => this.getParameters());
            } else {
              if (res.length) {
                this.setState({
                  ecuNameList: res,
                  selectedName: res[0].ecuName,
                  selectedEcuOtaFlsRefName: res[0].ecuOtaFlsRef,
                  selectedNameObj: res[0]
                }, () => this.getParameters());
              }
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  };

  onEcuNameChange(e) {
    const ecuName = e.target.value;
    let ecuNameObj;
    this.state.ecuNameList.map(item => {
      if (item.ecuName == ecuName) ecuNameObj = item;
    });
    this.setState({
      selectedName: ecuNameObj.ecuName,
      selectedEcuOtaFlsRefName: ecuNameObj.ecuOtaFlsRef,
      selectedNameObj: ecuNameObj,
      selectedParameter: {}
    }, () => this.getParameters());
  }

  getParameters = () => {
    const { type, selectedEcuOtaFlsRefName } = this.state;
    getParametersApi(type, selectedEcuOtaFlsRefName)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(response => {
            if (response.length) {
              const temp = [];
              response.map(item => {
                const obj = {};
                obj.value = item.id;
                obj.label = item.description;
                obj.lid = item.lidHex;
                obj.referenceId = item.referenceId;
                temp.push(obj);
              });
              this.setState({ parameters: temp });
            } else this.setState({ parameters: [] });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  selectParameters = (values) => {
    if (values !== null && values.referenceId) {
      this.setState({ isParameterSelected: true, selectedParameter: values });
    } else {
      this.setState({ isParameterSelected: false, selectedParameter: {} });
    }
  }

  handleRadioSelection = value => {
    this.setState({ checkboxValue: value, isVehicleSelected: false });
  };

  loadOptions = (inputValue) => (
    new Promise(async resolve => {
      const vehicles = await this.getVehicles(inputValue);
      resolve(vehicles);
    })
  );

  async getVehicles(inputValue) {
    const apiPromice = await vehicleSearchApi('vin', inputValue);
    const res = await apiPromice.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
      return [];
    } else {
      res.map(item => {
        item.value = item.vin;
        item.label = `${item.vin} (${item.serialNumber})`;
      });
      return res;
    }
  }

  selectVehicles = (values) => {
    if (values !== null && values.length) {
      this.setState({ isVehicleSelected: true, selectedVehicles: values });
    } else {
      this.setState({ isVehicleSelected: false, selectedVehicles: [] });
    }
  }

  validateFileName = (elem) => {
    if (elem.target.files.length && /\s/.test(elem.target.files[0].name)) {
      this.setState({ invalidFileName: true });
      return true;
    } else {
      this.setState({ invalidFileName: false, isVehicleSelected: true });
      return false;
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      invalidFileName,
      isVehicleSelected,
      isParameterSelected,
      selectedGroup,
      selectedName,
      selectedParameter
    } = this.state;

    if (isVehicleSelected === null) {
      this.setState({ isVehicleSelected: false });
    }
    if (isParameterSelected === null) {
      this.setState({ isParameterSelected: false });
    }
    if (form.checkValidity() && !invalidFileName && isVehicleSelected && isParameterSelected) {
      this.setState({ disable: true });
      const {
        description,
        file,
        scheduleExpiryDays,
        parameterValue
      } = event.target;

      // let lidHex = [];
      // this.state.selectedParameters.map(item => {
      //   if (!lidHex.includes(item.value)) {
      //     lidHex.push(item.value);
      //   }
      // });

      if (this.state.checkboxValue === "select") {
        let vehiclesData = [];
        this.state.selectedVehicles.map(item => {
          if (!vehiclesData.includes(item.vin)) {
            vehiclesData.push(item.vin);
          }
        });
        const payload = {
          description: description.value,
          ecuGroup: selectedGroup,
          ecuName: selectedName,
          vehicles: vehiclesData,
          scheduleExpiryDays: scheduleExpiryDays.value,
          parameterName: selectedParameter.label,
          parameterValue: parameterValue.value,
          referenceId : selectedParameter.referenceId,
          lidHex : [selectedParameter.lid]
        };
        if (this.state.edit) {
          this.editSchedule(payload)
        } else {
          this.createScheduleSelect(payload)
        }
      } else if (this.state.checkboxValue === "upload") {
        const payload = {
          description: description.value,
          ecuGroup: selectedGroup,
          ecuName: selectedName,
          scheduleExpiryDays: scheduleExpiryDays.value,
          parameterName: selectedParameter.label,
          parameterValue: parameterValue.value,
          referenceId : selectedParameter.referenceId,
          lidHex : selectedParameter.lid
        };
        const formData = new FormData();
        formData.append("file", file.files[0]);
        this.createScheduleUpload(formData, payload);
      }
    }
    this.setState({ validated: true });
  }

  createScheduleSelect = (payload) => {
    createUpdateParamScheduleSelectApi(payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  }

  createScheduleUpload = (file, payload) => {
    createUpdateParamScheduleUploadApi(file, payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  }

  editSchedule = (payload) => {
    editScheduleApi(this.state.editRow.scheduleId, payload)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            this.props.getSchedule('0');
            this.getErrorLogs(res);
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  }

  getErrorLogs = (metadata) => {
    errorLogsApi(metadata.message/*scheduleId*/)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            if (res.length !== 0) {
              metadata.successCode = "createScheduleWarning";
              metadata.warning = true;
              metadata.logs = res;
            } else {
              metadata.successCode = "createSchedule";
              metadata.warning = false;
            }
            this.props.onHide();
            this.props.showModalSuccess(metadata);
            this.setState({ disable: false });
          })
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, disable: false });
          })
        }
      })
      .catch(e => {
        this.setState({ error: e, disable: false });
        console.log(e);
      });
  }

  render() {
    const {
      edit,
      editRow,
      error,
      validated,
      selectedGroup,
      selectedGroupId,
      selectedName,
      selectedNameId,
      ecuGroupList,
      ecuNameList,
      parameters,
      isParameterSelected,
      selectedParameter,
      checkboxValue,
      selectedVehicles,
      isVehicleSelected,
      invalidFileName,
      scheduleExpiryDays,
      disable
    } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop={'static'}
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {edit ? "Edit Schedule" : "New Update Parameters Schedule"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Boolean(error) ? (
            <InlineAlert error={error} />
          ) : null}

          {/* Form Begins */}
          <Form
            noValidate
            encType="multipart/form-data"
            validated={validated}
            onSubmit={this.handleSubmit}
            >
            <Form.Row>
              {/* Ecu Group selection */}
              <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                <Form.Label>ECU Group</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="ecuGroup"
                  value={selectedGroup}
                  onChange={this.onEcuGroupChange}
                >
                  <option value="" disabled>
                    Select Ecu Group
                  </option>
                  {ecuGroupList && ecuGroupList.length ? (
                    <>
                      {ecuGroupList.map((data, i) => {
                        return (
                          <option key={`ecuGroup_${i}`} value={data.ecuGroupId}>
                            {data.ecuGroup}
                          </option>
                        );
                      })}{" "}
                    </>
                  ) : null}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please choose ECU group.
                </Form.Control.Feedback>
              </Form.Group>

              {/* Ecu Name Selection */}
              <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                <Form.Label>ECU Name</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="ecuNameId"
                  value={selectedName}
                  onChange={this.onEcuNameChange}
                  >
                  <option value="" disabled defaultValue>
                    Select Ecu Name
                  </option>
                  {ecuNameList && ecuNameList.length ? (
                    <>
                      {ecuNameList.map((data, i) => {
                        return (
                          <option key={`ecuName_${i}`} value={data.ecuNameId}>
                            {data.ecuName}
                          </option>
                        );
                      })}
                    </>
                  ) : null}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please choose ECU name.
                </Form.Control.Feedback>
              </Form.Group>

              {/* Parameter Selection */}
              <Form.Group as={Col} md="6" controlId="selectVehicles">
                <Form.Label>Select Parameter</Form.Label>
                <Select
                  options={parameters}
                  components={animatedComponents}
                  value={selectedParameter}
                  onChange={this.selectParameters}
                  isDisabled={selectedGroup===""}
                />
                {isParameterSelected !== null && !isParameterSelected ? (
                  <>
                    <small className="text-danger">
                      Please select a Parameter.
                    </small>
                  </>
                ) : null}
              </Form.Group>

              {/* Parameter Value */}
              <Form.Group as={Col} md="6" controlId="validationCustompv">
                <Form.Label>Parameter Value</Form.Label>
                <Form.Control
                  required
                  name="parameterValue"
                  type="text"
                  placeholder="Parameter Value"
                  maxLength={45}
                  defaultValue={edit ? editRow.description : null}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Parameter Value.
                </Form.Control.Feedback>
              </Form.Group>

              {/* Display Schedule Name on Edit only */}
              {edit ? (
                <Form.Group as={Col} md="12" controlId="validationCustom02">
                  <Form.Label>Schedule Name</Form.Label>
                  <Form.Control
                    disabled
                    name="name"
                    as="textarea"
                    rows="2"
                    placeholder={editRow.name}
                  />
                </Form.Group>
              ) : null}

              {/* Radio Selection */}
              {!edit ? (
                <>
                  <div className="d-flex flex-column px-1 mt-2 mb-2 width100">
                    <ToggleButtonGroup
                      type="radio"
                      name="options"
                      defaultValue={checkboxValue}
                      onChange={this.handleRadioSelection}
                    >
                      <ToggleButton variant="outline-primary" value={"select"}>Select VIN</ToggleButton>
                      <ToggleButton variant="outline-primary" value={"upload"}>Bulk Upload</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </>
              ) : null}

              {/* Select VIN Input */}
              {checkboxValue === "select" ? (
                <>
                  <Form.Group as={Col} md="12" controlId="selectVehicles">
                    <Form.Label>Search and Select VIN</Form.Label>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      value={selectedVehicles}
                      loadOptions={this.loadOptions}
                      components={animatedComponents}
                      onChange={this.selectVehicles}
                    />
                    {isVehicleSelected !== null && !isVehicleSelected ? (
                      <>
                        <small className="text-danger">
                          Please select one or more VIN.
                        </small>
                      </>
                    ) : null}
                  </Form.Group>
                </>
              ) : null}

              {/* Bulk Upload Input */}
              {checkboxValue === "upload" ? (
                <>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustomUsername"
                    onChange={this.validateFileName}
                  >
                    <Form.Label>Bulk Upload</Form.Label>
                    <Form.Control type="file" name="file" required />
                    <Form.Control.Feedback type="invalid">
                      Please choose File.
                    </Form.Control.Feedback>
                    {invalidFileName ? (
                      <>
                        <small className="text-danger">
                          File Name contains space. Please rename the file and try again.
                        </small>
                      </>
                    ) : null}
                  </Form.Group>
                </>
              ) : null}

              {/* Description area */}
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  required
                  name="description"
                  type="text"
                  placeholder="Description"
                  maxLength={45}
                  defaultValue={edit ? editRow.description : null}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter schedule description.
                </Form.Control.Feedback>
              </Form.Group>

              {/* schedule expiry selection */}
              <Form.Group as={Col} md="8" controlId="validationCustom01">
                <Form.Label>Schedule Expiry</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    required
                    type="text"
                    min="1"
                    name="scheduleExpiryDays"
                    max={365}
                    placeholder="Days"
                    minLength={1}
                    maxLength={3}
                    pattern="^(?:[1-9]\d?|[12]\d{2}|3[0-5]\d|36[0-5])$"
                    defaultValue={edit ? editRow.scheduleExpiryDays : scheduleExpiryDays}
                  />
                  <InputGroup.Append className="expiry-text">
                    <InputGroup.Text id="basic-addon2">
                      days from date of approval
                    </InputGroup.Text>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    Please select days within range from 1 to 365 only.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Button type="submit" disabled={disable}>
              {disable ? (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  role="status"
                />
              ) : null}
              {edit ? "Save" : "Create"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateUpdateParamSchedModal;
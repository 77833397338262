import React, { Component } from "react";
import { Container, Row, Col, Modal, Form, Button, Spinner } from "react-bootstrap";
import DateConverter from "../../shared/dateConverter/dateConverter";
import CustomDataTable from "../../shared/customDataTable/customDataTable";
import CreateButton from "../../shared/customButtons/createButton/createButton";
import UserAddModal from "./UserAddModal";
import { usersApi, userSearchApi, deleteUserApi, resetPasswordApi } from "../../utill/api/userApi";
import ToastAlert from "../../shared/notificationAlert/toastAlert";
import { permissionModules } from "../../utill/config";
import { userType } from "../../utill/config";
import ServerSearch from "../../shared/server-search/server-serach";
import UserActions from "./UserActions";
import ConfirmDeleteModal from "../../shared/modals/confirmDeleteModal";
import ResetPasswordModal from "./ResetPasswordModal";
/**
 *
 */
class Users extends Component {
  columns = [
    {
      name: "#",
      sortable: true,
      minWidth: "60px",
      maxWidth: "50px",
      cell: row => row.index
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      minWidth: "200px",
      cell: row => <div>{row.firstName} {row.lastName}</div>
    },
   
    {
      name: "EmailId",
      selector: "emailId",
      sortable: true,
      minWidth: "250px",
      cell: row => <div className="longString"><a href={`mailto:${row.emailId}`} target="_blank">{row.emailId}</a></div>
    },
    {
      name: "User Type",
      selector: "userType",
      sortable: true,
      minWidth: "250px",
      cell: row => <div className="longString">  <code> {userType[row.userType]}</code></div>
    },
    {
      name: "Phone Number",
      selector: "phoneNumber",
      sortable: true,
      minWidth: "200px",
      cell: row => <div>{row.phoneNumber}</div>
    },
    {
      name: "Created By",
      selector: "creatorName",
      sortable: true,
      minWidth: "200px",
      cell: row => <div>{row.creatorName}</div>
    },
    {
      name: "Created At",
      selector: "createdAt",
      sortable: true,
      minWidth: "200px",
      cell: row => <DateConverter date={row.createdAt} />
    },
    {
      name: "Action",
      sortable: true,
      minWidth: "300px",
      cell: row => (
        <>
          <UserActions
            row={row}
            confirmDelete={this.confirmDelete}
            edit={this.editUser}
            resetPassword={this.resetUserPassword}
          />
        </>
      )
    }
  ];
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  editRow = null;
  resetRow = {};
  currentPage = 0;

  searchDropdownlist = [
    { displayText: "First Name", key: "firstName" },
    { displayText: "Last Name", key: "lastName" },
    { displayText: "User Type", key: "userType" },
    { displayText: "Email Id", key: "emailId" },
    { displayText: "Phone Number", key: "phoneNumber" }
  ];

  constructor() {
    super();
    this.state = {
      usersData: [],
      isLoading: true,
      error: null,
      resetData: {},
      showDeleteModal: false,
      disableConfirmButton: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }
  componentDidMount() {
    /**
     * This is not called here because the useEffect Hook in the customDataTable 
     * component calls it automatically in the initial rendering.
     * This methodology is followed across all class components using customDataTable.
     */
    // this.getUsers();
    if (this.currentUser == null || this.currentUser === undefined) {
      localStorage.clear();
      this.props.history.push('/login');
    }
  }

  /**
   * To get files
   */
  getUsers(page) {
    usersApi(page)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setIndexNumber(res, page);
            } else this.setState({ isLoading: false });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }
  /**
   * To set index for each row
   */
  setIndexNumber = (data, page) => {
    if (page == 0) {
      this.setState({ usersData: [] });
    }
    data.map((row, index) => {
      return row.index = this.state.usersData.length + index + 1;
    });
    if (page == 0) {
      this.setState({
        usersData: data,
        isLoading: false
      })
    } else {
      this.setState(prevState => ({
        usersData: [...prevState.usersData, ...data],
        isLoading: false
      }));
    }
  };

  deleteUser = () => {
    this.setState({ disableConfirmButton: true });
    deleteUserApi(this.state.deleteData.userId)
      .then(res => {
        if (res.status === 200) {
          res.successCode = "deleteUserSuccess"
          this.setState({
            error: res,
            deleteData: {},
            showDeleteModal: false,
            disableConfirmButton: false
          },() => this.getUsers(0));
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              showDeleteModal: false,
              disableConfirmButton: false
            });
          });
        }
      })
      .catch(e => {
        this.setState({
          error: e,
          showDeleteModal: false,
          disableConfirmButton: false
        });
      });
  }

  editUser = row => {
    this.toggleModal();
    this.editRow = row;
  };

  toggleModal() {
    if (Boolean(this.editRow)) this.editRow = null;
    this.setState(oldState => ({
      showModal: !oldState.showModal
    }));
  }

  resetUserPassword = row => {
    this.setState({ resetRow: row },
      () => this.toggleResetModal()
    );
  };

  toggleResetModal = () => {
    this.setState(oldState => ({
      showResetModal: !oldState.showResetModal
    }));
  }

  confirmDelete = data => {
    if (!this.state.showDeleteModal) {
      this.setState({ showDeleteModal: true, deleteData: data });
    } else if (this.state.showDeleteModal) {
      this.setState({ showDeleteModal: false });
    }
  }

  errorHandled = () => {
    this.setState({ error: null });
  };

  showModalSuccess = (e) => {
    this.setState({ error: e });
  };

  loadNextPage = (page, currentPage) => {
    this.currentPage = currentPage;
    this.getUsers(page);
  };

  handleSearch = async (key, value) => {
    const apiPromice = await userSearchApi(key, value);
    const res = await apiPromice.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
    } else {
      this.setIndexNumber(res, 0);
    }
  };

  render() {
    return (
      <>
        {/* Toast Alert */}
        {Boolean(this.state.error) ? (
          <ToastAlert
            show={Boolean(this.state.error)}
            onClose={this.errorHandled}
            error={this.state.error}
          />
        ) : null}
        {/* Page Content starts */}
        <Container fluid>
          <Row noGutters className="page-title">
            <Col className="col-3">
              <h1>Users</h1>
            </Col>
            <Col className="col-5">
              {/* Search Dropdown */}
              <ServerSearch
                dropdown
                list={this.searchDropdownlist}
                search={this.handleSearch}
              />
            </Col>
            <Col className="col-4 text-right">
              {this.currentUser &&
                this.currentUser.userType &&
                permissionModules[this.currentUser.userType].admin.includes("create") ? (
                  <CreateButton
                    displaytext="New User"
                    onClick={() => this.setState({ showModal: true })}
                  />
                ) : null}
            </Col>
          </Row>
          <Row noGutters>
            <Col className="data-table">
              <CustomDataTable
                tableColumns={this.columns}
                data={this.state.usersData}
                loading={this.state.isLoading}
                loadNextPage={this.loadNextPage}
                currentPage={this.currentPage}
              />
            </Col>
          </Row>
        </Container>
        {this.state.showModal ? (
          <UserAddModal
            show={this.state.showModal}
            onHide={this.toggleModal}
            editRow={this.editRow}
            getUsers={this.getUsers}
            showModalSuccess={this.showModalSuccess}
          />
        ) : null}
        <ConfirmDeleteModal
          show={this.state.showDeleteModal}
          disable={this.state.disableConfirmButton}
          onHide={this.confirmDelete}
          update={this.deleteUser}
          action={"userDelete"}
        />
        <ResetPasswordModal
          show={this.state.showResetModal}
          onHide={this.toggleResetModal}
          row={this.state.resetRow}
          showModalSuccess={this.showModalSuccess}
        />
      </>
    );
  }
}

export default Users;

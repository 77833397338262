import apiHelper from "./apiHelper";

/**
 * 
 */
export const getSyncLogApi = (logType, page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/sync/logs/${logType}?page=${page}&limit=${100}`,
    method: "GET"
  })
};

export const getEcuSyncLogApi = (page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/sync/connectall/xml/logs?page=${page}&limit=${100}`,
    method: "GET"
  })
};
/**
 * 
 */
export const syncEcu = (file) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/sync/connectall/xml`,
    method: "POST",
    body: file
  })
};

export const syncPDVehicle = () => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/sync/manual/pd`,
    method: "POST"
  })
};

export const getIAlertLog = (page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/ialert/sync/stats?page=${page}&limit=${100}`,
    method: "GET"
  })
};

export const syncIAlert = () => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/ialert/sync`,
    method: "POST"
  })
};
import isEmpty from "lodash/isEmpty";

/***
 * default headers we pass for both GET and POST calls
 */

const defaultHeaders = () => {
  return {
    "Accept": "application/json",
    "Content-Type": "application/json"
  };
};

const fileUploadHeaders = () => {
  return {
    "Accept": "application/json",
  };
};

/**
 * This is one layer before pass the payload to electrode fetch.
 * This will help if there is a change required for all the api calls
 * @param {object} fetchObj .
 * @returns {object} promise.
 */
/* eslint-disable complexity */
const APIHelper = (fetchObj) => {
  let defaultAPI = null;
  if (!isEmpty(fetchObj)) {
    const { url = null, method = null, headers = {}, body = {} } = fetchObj;
    const customHeader = Object.assign({}, defaultHeaders(), headers);
    if (url && method && body && (method === "POST" || method === "post")) {
      if(body instanceof FormData) {
        const fileHeader = Object.assign({}, fileUploadHeaders(), headers);
        defaultAPI = fetch(url, {
          method,
          headers: fileHeader,
          body: body
        });
      }
      else {
        const customHeader = Object.assign({}, defaultHeaders(), headers);
        defaultAPI = fetch(url, {
          method,
          headers: customHeader,
          body: JSON.stringify(body)
        });
      }

    } else if (url && method && body && (method === "PUT" || method === "put")) {
      defaultAPI = fetch(url, {
        method,
        headers: customHeader,
        body: JSON.stringify(body)
      });
    } else if (url && method && body && (method === "PATCH" || method === "patch")) {
      defaultAPI = fetch(url, {
        method,
        headers: customHeader,
        body: JSON.stringify(body)
      });
    } else if (url && (method === "GET" || method === "get")) {
      const customHeaderdelete = Object.assign({}, defaultHeaders(), headers);
      defaultAPI = fetch(url, {
        method,
        headers: customHeaderdelete
      });
    } else if (url && (method === "DELETE" || method === "delete")) {
      const customHeaderdelete = Object.assign({}, defaultHeaders(), headers);
      defaultAPI = fetch(url, {
        method,
        headers: customHeaderdelete
      });
    } else {
      throw new Error("please supply the required fields");
    }
  } else {
    throw new Error("it is mandatory to pass request url and method");
  }
  return defaultAPI;
};

export default APIHelper;
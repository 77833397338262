import apiHelper from "./apiHelper";

export const scheduleApi = (status, page, searchKey, searchValue) => {
  let token = localStorage.getItem('token');
  let tempUrl = `${process.env.REACT_APP_DOMAIN}/schedules?page=${page}&limit=${100}`;
  if (status !== "ALL") {
    tempUrl += `&status=${status}`
  }
  if (searchValue) {
    tempUrl += `&searchKey=${searchKey}&searchValue=${searchValue}`
  }
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: tempUrl,
    method: "GET",
  })
};

export const scheduleVehicleSearchApi = (scheduleId, vin, page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${scheduleId}/vin/${vin}?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const createScheduleSelectApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules`,
    method: "POST",
    body: payload
  })
};

export const createScheduleUploadApi = (file, payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/upload` +
    `?description=${encodeURI(payload.description)}` +
    `&scheduleExpiryDays=${encodeURI(payload.scheduleExpiryDays)}` +
    `&ecuGroup=${encodeURI(payload.ecuGroup)}` +
    `&ecuName=${encodeURI(payload.ecuName)}` +
    `&fileName=${encodeURI(payload.fileName)}` +
    `&type=${encodeURI(payload.type)}`,
    method: "POST",
    body: file
  })
};

export const createReadParamScheduleSelectApi = (type, payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${type}`,
    method: "POST",
    body: payload
  })
};

export const createReadParamScheduleUploadApi = (type, file, payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${type}/upload` +
    `?description=${encodeURI(payload.description)}` +
    `&scheduleExpiryDays=${encodeURI(payload.scheduleExpiryDays)}` +
    `&ecuGroup=${encodeURI(payload.ecuGroup)}` +
    `&ecuName=${encodeURI(payload.ecuName)}` +
    `&lidHex=${encodeURI(payload.lidHex)}`,
    method: "POST",
    body: file
  })
};

export const createUpdateParamScheduleSelectApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/updateParameters`,
    method: "POST",
    body: payload
  })
};

export const createUpdateParamScheduleUploadApi = (file, payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/updateParameters/upload` +
    `?description=${encodeURI(payload.description)}` +
    `&scheduleExpiryDays=${encodeURI(payload.scheduleExpiryDays)}` +
    `&ecuGroup=${encodeURI(payload.ecuGroup)}` +
    `&ecuName=${encodeURI(payload.ecuName)}` +
    `&parameterName=${encodeURI(payload.parameterName)}` +
    `&parameterValue=${encodeURI(payload.parameterValue)}` +
    `&referenceId : ${payload.referenceId}` +
    `&lidHex : ${payload.lidHex}`,
    method: "POST",
    body: file
  })
};

export const getParametersApi = (type, ecuOtaFlsRefName, searchValue) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/${type}?ecuOtaFlsRefName=${ecuOtaFlsRefName}`,
    method: "GET",
  })
};

export const createIalertScheduleApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/ialert/schedules/flashEcu`,
    method: "POST",
    body: payload
  })
};

export const createIalertErrorScheduleApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/ialert/schedules`,
    method: "POST",
    body: payload
  })
};

export const errorLogsApi = (scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/error/log/${scheduleId}`,
    method: "GET",
  })
};

export const editScheduleApi = (scheduleId, payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${scheduleId}`,
    method: "PATCH",
    body: payload
  })
};

export const scheduleDetailApi = (scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${scheduleId}`,
    method: "GET",
  })
};

export const iAlertVehiclesApi = (page, scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/ialert/schedules/${scheduleId}/vehicle?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const scheduleVehiclesApi = (page, scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${scheduleId}/vehicle?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const readDtcVehiclesApi = (page, scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/diagnostic/readDtc/scheduleId/${scheduleId}?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const clearDtcVehiclesApi = (page, scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/diagnostic/cleardtc/schedule/${scheduleId}?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const updateParamVehiclesApi = (page, scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${scheduleId}/vehicle?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const readParamVehiclesApi = (page, scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/diagnostic/readParameters/scheduleId/${scheduleId}?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const freezeFrameVehiclesApi = (page, scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/diagnostic/freezeframe/schedule/${scheduleId}?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const readDtcDiagnosticsApi = (parentId, page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/diagnostic/readDtc/parentId/${parentId}?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const readParamDiagnosticsApi = (scheduleVehicleId, page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/diagnostic/readParameters/scheduleVehicleId/${scheduleVehicleId}?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const freezeFrameDiagnosticsApi = (scheduleVehicleId, ecuName, page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/diagnostic/freezeframe/schedule/vehicle/${scheduleVehicleId}/ecuName/${ecuName}?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const freezeFrameDiagParamApi = (parentId, page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/diagnostic/freezeframe/schedule/parent/${parentId}/data?page=${page}&limit=${100}`,
    method: "GET",
  })
};

export const approveRejectScheduleApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/updateStatus`,
    method: "PATCH",
    body: payload
  })
};

export const withdrawScheduleApi = (scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${scheduleId}/withdraw`,
    method: "PATCH"
  })
};

export const triggerScheduleApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/commands/trigger`,
    method: "POST",
    body: payload
  })
};

export const triggerBulkScheduleApi = (scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${scheduleId}/bulk-flash-trigger`,
    method: "POST"
   
  })
};

export const retryTriggerApi = (scheduleVehicleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/vehicle/${scheduleVehicleId}/retry`,
    method: "POST"
  })
};

export const remoteDiagnosticsApi = (vehicleId, page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/diagnostic/data/${vehicleId}`,
    method: "GET"
  })
};

export const vehicleActivityApi = (scheduleVehicleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/devices/activity/scheduleVehicleId/${scheduleVehicleId}`,
    // ?serialNumber=${serialNumber}&startDate=${startDate}&endDate=${endDate}`,
    method: "GET"
  })
};

export const prevVehicleActivityApi = (vin, page) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/history/vin/${vin}?page=${page}&limit=10`,
    // ?serialNumber=${serialNumber}&startDate=${startDate}&endDate=${endDate}`,
    method: "GET"
  })
};

export const forceCancelApi = (serialNumber) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/serialNumber/${serialNumber}/cancel`,
    method: "PATCH"
  })
};

export const deleteScheduleApi = (scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${scheduleId}`,
    method: "DELETE"
  })
};

export const createDTCScheduleSelectApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/scheduleDiagnostics`,
    method: "POST",
    body: payload
  })
};

export const createDTCScheduleUploadApi = (file, payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/scheduleDiagnostics/upload` +
    `?description=${encodeURI(payload.description)}` +
    `&scheduleExpiryDays=${encodeURI(payload.scheduleExpiryDays)}` +
    `&ecuGroup=${encodeURI(payload.ecuGroup)}` +
    `&ecuName=${encodeURI(payload.ecuName)}` +
    `&type=${encodeURI(payload.type)}`,
    method: "POST",
    body: file
  })
};

export const getCancelStatusApi = (serialNumber) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/serialNumber/${serialNumber}/cancel/status`,
    method: "GET"
  })
};

export const recordCancelActivityApi = (serialNumber) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/schedules/serialNumber/${serialNumber}/flash-cancel-activity`,
    method: "PUT",
    body: {}
  })
};

export const validateFileApi = (payload, ecuGroup, supplier,fileType,filesNames,scheduleType) => {
  let tempUrl = `${process.env.REACT_APP_DOMAIN}/ialert/schedules/validate?ecuGroup=${ecuGroup}`;
  if (supplier) tempUrl += `&supplier=${supplier}`;

  tempUrl += `&fileType=${encodeURIComponent(fileType)}`;
  tempUrl += `&fileNames=${filesNames}`;
  tempUrl += `&scheduleType=${scheduleType}`;
  
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Authorization": "Bearer " + token},
    url: tempUrl,
    method: "POST",
    body: payload
  })
};

export const validateVinsApi = (payload) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token},
    url: `${process.env.REACT_APP_DOMAIN}/ialert/schedules/vehicle/validate`,
    method: "POST",
    body: payload
  })
};

export const downloadReportApi = (type, scheduleId) => {
  let token = localStorage.getItem('token');
  return apiHelper({
    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    url: `${process.env.REACT_APP_DOMAIN}/schedules/${type}/${scheduleId}/download`,
    method: "GET",
  })
};
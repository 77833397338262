import React from "react";
import { Toast } from "react-bootstrap";
import { successCodes } from "../../utill/config";
import { logoutApi } from "../../utill/api/loginApi";
// import { useHistory } from "react-router-dom";

const ToastAlert = props => {
  let background = "bg-primary";
  let textColor = "text-white";
  // let history = useHistory();

  const logOut = (e) => {
    const encodedToken = localStorage.getItem("encodedToken");
    // logoutApi(encodedToken)
    localStorage.clear();
    // history.push("/");
    window.location.reload();
    // localStorage.setItem("timeOut", JSON.stringify(props.error));
    // this.setState({ loggedIn: false })
  }

  if (props.error.status) {
    if (props.error.status === 200) {
      background = "bg-success";
      if (props.error.warning) {
        background = "bg-warning";
      }
    } else if (props.error.status === 401) {
      background = "bg-danger";
      props.error.message = "Session Timed Out."
      logOut()
    } else {
      background = "bg-danger";
    }
  }

  const closeToastTimer = () => {
    setTimeout(() => {
      props.onClose();
    }, 5000);
  };
  if(!props.error.warning) {
    closeToastTimer();
  }
  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{ position: "relative", minHeight: "0px", zIndex: 99 }}
      >
        <Toast
          className={background}
          show={props.show}
          onClose={props.onClose}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          {props.error.status && props.error.status === 200 ? (
            <>
              {props.error.warning ? (
                <>
                  <Toast.Header>
                    <strong className="mr-auto">WARNING</strong>
                  </Toast.Header>
                  <Toast.Body className="text-dark">
                    {props.error.successCode
                      ? successCodes[props.error.successCode]
                      : "Action Completed Successfully with Warnings."}
                  </Toast.Body>
                  <Toast.Body>
                    <ul>
                      {props.error.logs.map(log => {
                        return (
                          <li>
                            <p><strong>VIN: </strong>{log.vin}</p>
                            <p>{log.log}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </Toast.Body>
                </>
              ) : (
                <>
                  <Toast.Header>
                    <strong className="mr-auto">SUCCESS</strong>
                  </Toast.Header>
                  <Toast.Body className="text-white">
                    {props.error.successCode
                      ? successCodes[props.error.successCode]
                      : "Action Completed Successfully."}
                  </Toast.Body>
                </>
              )}
            </>
          ) : (
            <>
              <Toast.Header>
                <strong className="mr-auto">{props.error.error}</strong>
              </Toast.Header>
              <Toast.Body className={textColor}>
                {/* {props.error.errorCode && errorCodes[props.error.errorCode]
                ? errorCodes[props.error.errorCode]
                : "Internal Server Error."} */}
                {props.error.message}
              </Toast.Body>
            </>
          )}
        </Toast>
      </div>
    </>
  );
};

export default ToastAlert;

import React, { Component } from 'react';
import { Form, Col, Button, Spinner, InputGroup } from 'react-bootstrap';
import AsyncSelect from "react-select/async";
import makeAnimated from 'react-select/animated';
import {
  vehiclesApi,
  vPartNumberApi,
  ecuDetailsApi,
  supplierDetailsApi
} from '../../../../utill/api/ecuApi';
import InlineAlert from '../../../../shared/notificationAlert/inlineAlert';

const animatedComponents = makeAnimated();

class ReadClearDtcManual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      disable: false,
      error: null,
      type: this.props.type,
      isVehicleSelected: null,
      selectedVehicles: [],
      isVpartSelected: null,
      selectedVpart: null,
      disableVpartSelect: false,
      ecuDetails: [],
      selectedEcuId: '',
      selectedEcu: '',
      ecuGroup: '',
      ecuName: '',
      supplierDetails: [],
      selectedSupplier: '',
      selectedSupplierId: '',
      selectedIAlertVpartId: '',
      selectedIAlertVpart: '',
      fileList: [],
      isFileSelected: null,
      selectedFile: null,
    }
    this.getVehicles = this.getVehicles.bind(this);
    this.getVpartNumbers = this.getVpartNumbers.bind(this);
    this.getEcuDetails = this.getEcuDetails.bind(this);
    this.getSupplierDetails = this.getSupplierDetails.bind(this);
  }

  componentDidMount() { }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ disable: nextProps.disable });
  }

  loadVinOptions = (inputValue) => (
    new Promise(async resolve => {
      const vehicles = await this.getVehicles(inputValue);
      resolve(vehicles);
    })
  );

  async getVehicles(inputValue) {
    const { selectedVpart } = this.state;
    const vpart = (selectedVpart === null) ? undefined : selectedVpart.vPartNumber;
    const apiPromice = await vehiclesApi(inputValue, vpart);
    const res = await apiPromice.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
      return [];
    } else {
      res.map(item => {
        item.value = item.vin;
        item.label = `${item.vin} (${item.serialNumber})`;
      });
      return res;
    }
  }

  selectVehicles = (values) => {
    if (values !== null && values.length) {
      if (
        (this.state.isVehicleSelected === null || !this.state.isVehicleSelected) &&
        (this.state.selectedVpart === null || !Object.keys(this.state.selectedVpart).length)
      ) {
        const selectVpart = JSON.parse(JSON.stringify(values[0]));;
        selectVpart.value = selectVpart.vehicleId;
        selectVpart.label = selectVpart.vPartNumber;
        this.setState(
          { isVpartSelected: true, selectedVpart: selectVpart },
          () => this.getEcuDetails()
        );
      }
      this.setState({
        isVehicleSelected: true,
        selectedVehicles: values,
        disableVpartSelect: true
      });
    } else {
      this.setState({
        isVehicleSelected: false,
        selectedVehicles: [],
        isVpartSelected: false,
        selectedVpart: null,
        disableVpartSelect: false,
        ecuDetails: [],
        selectedEcuId: '',
        selectedEcu: '',
        ecuGroup: '',
        ecuName: '',
        supplierDetails: [],
        selectedSupplierId: '',
        selectedSupplier: '',
        fileList: [],
        isFileSelected: null,
        selectedFile: null,
      });
    }
  }

  loadVpartOptions = (inputValue) => (
    new Promise(async resolve => {
      const vpart = await this.getVpartNumbers(inputValue);
      resolve(vpart);
    })
  );

  async getVpartNumbers(inputValue) {
    const apiPromice2 = await vPartNumberApi(inputValue);
    const res = await apiPromice2.json();
    if (res.error && res.status !== 200) {
      this.setState({ error: res });
      return [];
    } else {
      res.map(item => {
        item.value = item.vehicleId;
        item.label = item.vPartNumber;
      });
      return res;
    }
  }

  selectVpartNumber = (value) => {
    if (value !== null) {
      this.setState(
        { isVpartSelected: true, selectedVpart: value },
        () => this.getEcuDetails()
      );
    } else {
      this.setState({ isVpartSelected: false, selectedVpart: {} });
    }
  }

  getEcuDetails() {
    ecuDetailsApi(this.state.selectedVpart.vPartNumber, false)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(ecu => {
                ecu.label = `${ecu.ecuGroup}`;
              });
              this.setState({
                ecuDetails: res,
                selectedEcuId: res[0].id,
                selectedEcu: res[0].label,
                ecuGroup: res[0].ecuGroup,
                // ecuName: res[0].model,
              }, () => this.getSupplierDetails());
            } else {
              this.setState({
                ecuDetails: [],
                selectedEcuId: '',
                selectedEcu: '',
                ecuGroup: '',
                ecuName: '',
                supplierDetails: [],
                selectedSupplier: '',
                selectedSupplierId: '',
                selectedIAlertVpartId: '',
                selectedIAlertVpart: ''
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  getSupplierDetails() {
    supplierDetailsApi(this.state.selectedEcu, this.state.selectedVpart.vPartNumber)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res });
          });
        } else {
          res.json().then(res => {
            if (res.length) {
              res.map(sup => { sup.label = `${sup.supplier}` });
              this.setState({
                supplierDetails: res,
                selectedSupplier: res[0].label,
                selectedSupplierId: res[0].id,
                selectedIAlertVpartId: res[0].iAlertVPartNumberId,
                selectedIAlertVpart: res[0].vPartNumber,
                ecuName: res[0].connectAllEcuName
              }, /*() => this.getDefaultFiles()*/);
            } else {
              this.setState({
                supplierDetails: [],
                selectedSupplier: '',
                selectedSupplierId: '',
                selectedIAlertVpartId: '',
                ecuName: '',
                selectedIAlertVpart: ''
              });
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ error: e });
      });
  }

  // getDefaultFiles = () => {
  //   const { ecuGroup, ecuName, type } = this.state;
  //   filesApi(ecuName, ecuGroup, type, "")
  //     .then(res => {
  //       if (res.status !== 200) {
  //         res.json().then(res => {
  //           this.setState({ error: res });
  //         });
  //       } else {
  //         res.json().then(res => {
  //           if (res.length) {
  //             res.map(item => {
  //               item.label = item.fileName;
  //               item.value = item.id;
  //             });
  //             this.setState({ fileList: res, selectedFile: res[0], isFileSelected: true });
  //           } else {
  //             this.setState({ fileList: [], selectedFile: null, isFileSelected: null });
  //           }
  //         })
  //       }
  //     })
  //     .catch(e => {
  //       console.log(e);
  //       this.setState({ error: e });
  //     });
  // }

  // loadFileOptions = (inputValue) => (
  //   new Promise(async resolve => {
  //     const files = await this.getFiles(inputValue);
  //     resolve(files);
  //   })
  // );

  // async getFiles(inputValue) {
  //   const { ecuGroup, ecuName, type } = this.state;
  //   const apiPromice = await filesApi(ecuGroup, ecuName, type, inputValue)
  //   const res = await apiPromice.json();
  //   if (res.error && res.status !== 200) {
  //     this.setState({ error: res });
  //     return [];
  //   } else {
  //     res.map(item => {
  //       item.value = item.id;
  //       item.label = item.fileName;
  //     });
  //     return res;
  //   }
  // }

  // selectFile = (value) => {
  //   if (value !== null) {
  //     this.setState({ selectedFile: value, isFileSelected: true });
  //   } else {
  //     this.setState({ selectedFile: null, isFileSelected: false });
  //   }
  // }

  onEcuGroupChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { ecuDetails } = this.state;
    this.setState({
      selectedEcu: ecuDetails[selectedIndex].label,
      selectedEcuId: ecuDetails[selectedIndex].id,
      ecuGroup: ecuDetails[selectedIndex].ecuGroup,
      // ecuName: ecuDetails[selectedIndex].model
    }, () => this.getSupplierDetails());
  }

  onSupplierChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const { supplierDetails } = this.state;
    this.setState({
      selectedSupplier: supplierDetails[selectedIndex].label,
      selectedSupplierId: supplierDetails[selectedIndex].id,
      selectedIAlertVpartId: supplierDetails[selectedIndex].iAlertVPartNumberId,
      selectedIAlertVpart: supplierDetails[selectedIndex].vPartNumber,
      ecuName: supplierDetails[selectedIndex].connectAllEcuName
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const {
      type,
      isVehicleSelected,
      isVpartSelected,
      selectedEcu,
      selectedSupplier,
      selectedIAlertVpart
    } = this.state;
    if (isVehicleSelected === null) {
      this.setState({ isVehicleSelected: false });
    }
    if (isVpartSelected === null) {
      this.setState({ isVpartSelected: false });
    }
    // if (isFileSelected === null) {
    //   this.setState({ isFileSelected: false });
    // }
    if (form.checkValidity() && isVehicleSelected && isVpartSelected /*&& isFileSelected*/) {
      this.setState({ disable: true });
      const {
        description,
        scheduleExpiryDays
      } = event.target;
      // Data stored in state (vehicles)
      let vins = [];
      this.state.selectedVehicles.map(item => {
        if (!vins.includes(item.vin)) {
          vins.push(item.vin);
        }
      });
      // const ecus = [
      //   {
      //     fileName: selectedFile.fileName,
      //     iAlertSupplierId: selectedSupplierId
      //   }
      // ];
      // structure the payload
      // old
      // const payload = {
      //   description: description.value,
      //   scheduleExpiryDays: scheduleExpiryDays.value,
      //   type: type,
      //   vehicles: vins,
      //   ecus: ecus
      // };
      // new
      const payload = {
        description: description.value,
        scheduleExpiryDays: scheduleExpiryDays.value,
        type: type,
        vehicles: vins,
        ecuGroup: selectedEcu,
        supplier: selectedSupplier,
        vPartNumber: selectedIAlertVpart
      };
      // if (['readDtc', 'clearDtc', 'readFreezeFrame'].includes(this.state.type)) {
      //   this.createDTCScheduleSelect(payload);
      // } else this.createSchedule(payload);
      this.props.createSchedule(payload);
    }
    this.setState({ validated: true });
  };

  // createSchedule = (payload) => {
  //   createIalertErrorScheduleApi(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         res.json().then(res => {
  //           this.props.getSchedule('0');
  //           this.getErrorLogs(res);
  //         })
  //       } else if (res.status !== 200) {
  //         res.json().then(res => {
  //           this.setState({ error: res, disable: false });
  //         })
  //       }
  //     })
  //     .catch(e => {
  //       this.setState({ error: e, disable: false });
  //       console.log(e);
  //     });
  // };

  // createDTCScheduleSelect = (payload) => {
  //   createDTCScheduleSelectApi(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         res.json().then(res => {
  //           this.props.getSchedule('0');
  //           this.getErrorLogs(res);
  //         })
  //       } else if (res.status !== 200) {
  //         res.json().then(res => {
  //           this.setState({ error: res, disable: false });
  //         })
  //       }
  //     })
  //     .catch(e => {
  //       this.setState({ error: e, disable: false });
  //       console.log(e);
  //     });
  // };

  // getErrorLogs = (metadata) => {
  //   errorLogsApi(metadata.message/*scheduleId*/)
  //     .then(res => {
  //       if (res.status === 200) {
  //         res.json().then(res => {
  //           if (res.length !== 0) {
  //             metadata.successCode = "createScheduleWarning";
  //             metadata.warning = true;
  //             metadata.logs = res;
  //           } else {
  //             metadata.successCode = "createSchedule";
  //             metadata.warning = false;
  //           }
  //           this.props.onHide();
  //           this.props.showModalSuccess(metadata);
  //           this.setState({ disable: false });
  //         })
  //       } else if (res.status !== 200) {
  //         res.json().then(res => {
  //           this.setState({ error: res, disable: false });
  //         })
  //       }
  //     })
  //     .catch(e => {
  //       this.setState({ error: e, disable: false });
  //       console.log(e);
  //     });
  // };

  render() {
    const {
      isVehicleSelected,
      isVpartSelected,
      isFileSelected,
      selectedVpart,
      disableVpartSelect,
      ecuDetails,
      selectedEcu,
      supplierDetails,
      selectedSupplier,
      selectedFile,
      fileList
    } = this.state;
    return (
      <>
        {/* Error Display */}
        {Boolean(this.state.error) ? (
          <InlineAlert error={this.state.error} />
        ) : null}
        <Form
          noValidate
          encType="multipart/form-data"
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
          >
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="vin">
              <Form.Label>Search and Select VIN</Form.Label>
              <AsyncSelect
                isMulti
                loadOptions={this.loadVinOptions}
                components={animatedComponents}
                onChange={this.selectVehicles}
              />
              {isVehicleSelected !== null && !isVehicleSelected ? (
                <>
                  <small className="text-danger">
                    Please select one or more VIN.
                  </small>
                </>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="vpart">
              <Form.Label>Search and Select a V Part Number</Form.Label>
              <AsyncSelect
                value={selectedVpart}
                loadOptions={this.loadVpartOptions}
                components={animatedComponents}
                onChange={this.selectVpartNumber}
                isDisabled={disableVpartSelect}
              />
              {isVpartSelected !== null && !isVpartSelected ? (
                <>
                  <small className="text-danger">
                    Please select V Part Number.
                  </small>
                </>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="ecuGroup">
              <Form.Label>ECU Group</Form.Label>
              <Form.Control
                as="select"
                required
                name="ecuGroup"
                value={selectedEcu}
                onChange={this.onEcuGroupChange}
                >
                {ecuDetails.map((item, i) => {
                  return (
                    <option key={i} id={item.id} value={item.label}>{item.label}</option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a Group.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="supplier">
              <Form.Label>Supplier</Form.Label>
              <Form.Control
                as="select"
                required
                name="supplier"
                value={selectedSupplier}
                onChange={this.onSupplierChange}
                >
                {supplierDetails.map((item, i) => {
                  return (
                    <option key={i} id={item.id} value={item.label}>{item.label}</option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a Supplier.
              </Form.Control.Feedback>
            </Form.Group>

            {/* <Form.Group as={Col} md={6} controlId="fileName">
              <Form.Label>Choose File</Form.Label>
              <AsyncSelect
                cacheOptions
                defaultOptions={fileList}
                value={selectedFile}
                loadOptions={this.loadFileOptions}
                components={animatedComponents}
                // onChange={this.selectFile}
                noOptionsMessage={() => "No Files Found."}
              />
              {isFileSelected !== null && !isFileSelected ? (
                <>
                  <small className="text-danger">
                    Please select a file.
                  </small>
                </>
              ) : null}
            </Form.Group> */}

            <Form.Group as={Col} md="12" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder="Description"
                maxLength={45}
              />
              <Form.Control.Feedback type="invalid">
                Please enter schedule description.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="8" controlId="scheduleExpiry">
              <Form.Label>Schedule Expiry</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  required
                  type="text"
                  min="1"
                  name="scheduleExpiryDays"
                  max={365}
                  placeholder="Days"
                  minLength={1}
                  maxLength={3}
                  pattern="^(?:[1-9]\d?|[12]\d{2}|3[0-5]\d|36[0-5])$"
                />
                <InputGroup.Append className="expiry-text">
                  <InputGroup.Text id="basic-addon2">
                    days from date of approval
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  Please select days within range from 1 to 365 only.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>

          <Button type="submit" disabled={this.state.disable} style={{ float: 'right' }}>
            {this.state.disable ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                role="status"
              />
            ) : null}
            Create
          </Button>
        </Form>
      </>
    );
  }
}

export default ReadClearDtcManual;
import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";

const ViewSideMenu = (props) => {
  const {ecuName} = props;
  if (ecuName) {
  }
  return (
    <>
      <Accordion defaultActiveKey="">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="engineEcu">
            Engine ECU
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="engineEcu">
            <>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EDC 17 EGR</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EDC 17</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EGC 10</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;OH6</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;DELPHI</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EDC 15</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EDC 16</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BOSCH - BSVI</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Denso - BSVI</Card.Body>
            </>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="evcu">
            EVCU
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="evcu">
            <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EVCU</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="bcu">
            BCU
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="bcu">
            <>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU Bus Front</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU Bus Rear</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU Truck LS</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU Truck LE</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU ICV</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU MBP Truck BSIV</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU MBP Truck BSVI</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU BSVI Bus</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU BSIV Bus</Card.Body>
            </>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="cluster">
            Cluster
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="cluster">
            <>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Cluster EEA - BSIV</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Cluster EEA - BSVI</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Cluster - EV</Card.Body>
              <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Cluster - Non-Multiplexing</Card.Body>
            </>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="mux">
            MUX
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="mux">
            <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;MUX</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="acu">
            ACU
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="acu">
            <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Cummins ACU - BSVI</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="tcu">
            TCU
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="tcu">
            <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;TCU - BSVI</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>);
}

export default ViewSideMenu;

// class ViewSideMenu extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       ecuName: props.ecuName
//     };
//   }

//   render() {
//     const {ecuName} = this.state;
//     return (
//       <Accordion defaultActiveKey="mux">
//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="engineEcu">
//             Engine ECU
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="engineEcu">
//             <>
//             <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EDC 17 EGR</Card.Body>
//             <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EDC 17</Card.Body>
//             <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EGC 10</Card.Body>
//             <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;OH6</Card.Body>
//             <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;DELPHI</Card.Body>
//             <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EDC 15</Card.Body>
//             <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EDC 16</Card.Body>
//             <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BOSCH - BSVI</Card.Body>
//             <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Denso - BSVI</Card.Body>
//             </>
//           </Accordion.Collapse>
//         </Card>
//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="evcu">
//             EVCU
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="evcu">
//           <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;EVCU</Card.Body>
//           </Accordion.Collapse>
//         </Card>
//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="bcu">
//             BCU
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="bcu">
//             <>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU Bus Front</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU Bus Rear</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU Truck LS</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU Truck LE</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU ICV</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU MBP Truck BSIV</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU MBP Truck BSVI</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU BSVI Bus</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;BCU BSIV Bus</Card.Body>
//             </>
//           </Accordion.Collapse>
//         </Card>
//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="cluster">
//             Cluster
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="cluster">
//             <>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Cluster EEA - BSIV</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Cluster EEA - BSVI</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Cluster - EV</Card.Body>
//               <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Cluster - Non-Multiplexing</Card.Body>
//             </>
//           </Accordion.Collapse>
//         </Card>
//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="mux">
//             MUX
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="mux">
//           <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;MUX</Card.Body>
//           </Accordion.Collapse>
//         </Card>
//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="acu">
//             ACU
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="acu">
//           <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;Cummins ACU - BSVI</Card.Body>
//           </Accordion.Collapse>
//         </Card>
//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="tcu">
//             TCU
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="tcu">
//           <Card.Body><i className="fa fa-chevron-right"></i>&nbsp;TCU - BSVI</Card.Body>
//           </Accordion.Collapse>
//         </Card>
//       </Accordion>
//     );
//   }
// }

// export default ViewSideMenu;
